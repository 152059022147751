<app-navbar></app-navbar>
<div class="whe">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
        <source src="\assets\services\wealth-management\AAA+ Banner - Wealth Management and Investments.mp4" type="video/mp4">
        </video>
    </div>


    <div class="container">
        <h1 class="mainTitleBlack corporateTitle montserratSemiBold pt-5 pb-3">Wealth Management <br> and Investments</h1>

        <p class="mainText container-text corporateText container-text centerDiv">It doesn’t matter if this is your first time in the investment arena or have many years of experience; we can design a perfectly fitted wealth plan to achieve your financial goals. <br> <br> Creating wealth is no easy task. That’s why in order
            to provide great investment opportunities, we take into consideration estate planning, asset protection, retirement planning, and place of residency, among others. We have access to a vast variety of investment opportunities around the globe,
            such as fixed income instruments, stocks, mutual funds, private equity, direct lending programs, private placements, structured notes, hedge funds, commodities, currencies, retirement and student funds, insurance vehicles, real estate, among
            others. AAA+ makes sure that your investment is the best possible combination of risk versus return, but also that it fits into your overall financial goals.
        </p>
    </div>

    <br> <br> <br> <br>
    <!--  <div class="jumbotron jumbotron-fluid jumbotron-blue py-5 conf">

        <div class="container py-3">
            <div class="row py-5">
                <div class="col-3">
                    <img class=" svgText img-ca" src="\assets\services\wealth-management\HLM Artboard 32.svg" alt="">
                </div>
                <div class="col">
                    <h3 class="confidentialityTitle pb-2">
                        CONFIDENTIALITY
                    </h3>
                    <p class="confidentialityText">
                        In AAA+ we are committed to protect your confidentiality, which is accomplished by using closed and restricted channels of communication. Additionally, a single team of experts will give you all kinds of counseling necessary to achieve your goals.
                    </p>
                </div>
            </div>

            <div class="row pt-3">
                <div class="col">
                    <h3 class="confidentialityTitle ">
                        INTEGRITY
                    </h3>
                    <p class="confidentialityText">
                        We work to increase the reputation of AAA+ and our staff. Our consultants with high ethic and moral values work to keep long-term and solid relationships with our clients.
                    </p>
                </div>
                <div class="col-3">
                    <img class="svgText" src="\assets\services\wealth-management\HLM Artboard 33.svg" alt="">
                </div>
            </div>
        </div>
    </div>
 -->
    <div>
        <app-explore-our-services></app-explore-our-services>
    </div>

    <div class="pt-5">
        <app-mini-contact-us></app-mini-contact-us>
    </div>

    <div class="pt-5">
        <app-footer></app-footer>
    </div>
</div>