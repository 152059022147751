<app-menu></app-menu>
<div class="corp">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
      <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
        <source src="\assets\cambios\Servicios-Planificación_corporativa_internacional\44-Planificación corporativa.mp4" type="video/mp4">
      </video>
    </div>

    <div class="container">
        <h1 class="mainTitleBlack py-5">PLANIFICACIÓN <br> CORPORATIVA INTERNACIONAL</h1>
        <p class="corporateText container-text customT centerDiv"> <strong>AAA+</strong> ofrece servicios en muchas jurisdicciones, nuestro objetivo es diseñar una estructura corporativa internacional que le ayude a alcanzar sus metas a través de un servicio personalizado y eficiente. Adicionalmente, nos aseguramos que la información de nuestros clientes permanezca confidencial.
          <br><br> Hemos brindado asesoría a compañías y empresas familiares alrededor del mundo diseñando la estructura corporativa internacional más apropiada que permite expandir negocios, proteger activos, y emprender nuevos proyectos tomando en consideración la confidencialidad, protección legal, lugar de residencia, entre otros.
            <br><br> <strong>AAA+</strong> establece las estructuras corporativas internacionales a través de alianzas con bufetes de abogados y fiduciarios reconocidos, estos aliados son expertos en la materia con muchos años de experiencia.
        </p>
    </div>

    <br><br><br><br>
    <div class="row py-5 customT customB pad-image">
        <div class="col-12 col-md-6"><img class="manImg" src="\assets\services\corporate\two-businesspeople-discussing-.png" alt=""></div>

        <div class="col-12 col-md-6 py-4 ">
            <h2 class="titleTeam"><br> INCORPORACIÓN​ DE EMPRESAS INTERNACIONALES (IBC)</h2> <br> <br>
            <p class="textTeam container-text1"> <strong>AAA+</strong> tiene el compromiso de brindar el acompañamiento personalizado, en el proceso de la constitución de International Business Company (IBC), con un tiempo eficiente en la incorporación.
            </p>
        </div>

    </div>
    <div class="jumbotron jumbotron-fluid jumbotron-grey pt-5 pb-3 pad-image">
        <div class="row">
            <div class="col-12 col-md-6 container-text2 container-mobile les-tx">
                <h2 class="titleTeam"><br><br> FUNDACIÓN DE INTERÉS PRIVADO / <br> INCORPORACIÓ​N Y ADMINISTRACIÓN DE FIDEICOMISOS</h2> <br> <br>

                <p class="textTeam">
                    Si usted está en búsqueda de un vehículo de planificación sucesoria eficiente, podemos ayudarle en la incorporación de una Fundación de Interés Privado / Fideicomiso en varias jurisdicciones. Estos tipos de vehículos forman parte de una estructura que
                    provee la mayor protección para un patrimonio duradero manteniendo los deseos de los fundadores y fideicomitentes para las futuras generaciones.
                    <br> <br> Podemos asesorarle en qué tipo de estructura es la mejor para cada persona o familia tomando en cuenta nacionalidad, país de residencia, necesidades familiares y operacionales, los activos a proteger y las jurisdicciones
                    en las que estos se encuentran, entre otros.
                </p>
            </div>

            <div class="col-12 col-md-6 containerManImg2"><img class="manImg" src="\assets\services\corporate\man-working-at-night.png" alt=""></div>
        </div>

    </div>

    <div class="jumbotron jumbotron-fluid pt-1 pad-image">
        <div class="row pt-5">
            <div class="col-12 col-md-6"><img class="manImg" src="\assets\services\corporate\team-work-process-young-busine@2x.png" alt=""></div>
            <div class="col-12 col-md-6 py-3 px-5 les-tx">
                <h2 class="titleTeam"> <br> <br> <br> SERVICIOS PROFESIONALES <br> DE ANONIMATO
                </h2> <br> <br>
                <p class="textTeam container-text1">
                    En caso de ser necesario, podemos proporcionar servicios de Director, Consejero, Protector y Accionista para todo tipo de entidades. Estos servicios proporcionan un mayor nivel de seguridad y anonimato para su protección a la vez que garantizan que sus
                    activos se resguarden de la manera más fiable.
                </p>
            </div>

        </div>

    </div>

    <div class="py-5">
        <app-explorar-servicios></app-explorar-servicios>
    </div>

    <div class="pt-5">
        <app-mini-contacto></app-mini-contacto>
    </div>

    <div class="pt-5">
        <app-pie-pagina></app-pie-pagina>
    </div>
</div>
