<app-menu></app-menu>
<div class="ser">


    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
            <source src="\assets\services\main\AAA+ Banner - Services 01.mp4" type="video/mp4">
        </video>
    </div>

    <h1 class="mainTitle py-5 customB">NUESTRAS SOLUCIONES</h1>


    <div class="row pt-3 customT">
        <div class="col-12 col-sm-6 d-none d-sm-none d-md-block col-img">
            <img class="imgStyle" src="\assets\services\main\two-young-businessman-having-s.png" alt="">
        </div>

        <div class="col-12 col-sm-6 mh">
            <ul type="A" class="textList container-text ">
                <li class="my-3">Soluciones one-stop-shop para cada persona, familia o empresa.</li>
                <li class="my-3">Servicio personalizado y eficiente.</li>
                <li class="my-3">Confidencialidad y discreción</li>
                <li class="my-3">Oportunidades</li>
                <li class="my-3">Seguridad y protección de sus activos patrimoniales</li>
                <li class="my-3">Respaldo especialmente diseñado para la tranquilidad económica de sus seres queridos</li>
            </ul>
            <br><br>
            <p class="textUnderList container-text centerDiv">
              Nuestros asesores altamente calificados le guiarán con estrategias de gestión patrimonial para alcanzar sus metas financieras, a través de estructuras patrimoniales hechas a la medida.
            </p>
        </div>
    </div>


    <div class="war-icon">
        <div class="img-war">
            <img src="../../../../assets/footer/HLM Artboard 37.svg" alt="">
        </div>
        <div class="txt-war">
            <p>Inversión inmobiliaria en Florida</p>
        </div>
        <div class="btn-war">
            <button class="btn-custom"> <a routerLink="/contact">Leer más</a></button>
        </div>
    </div>

    <div class="pt-5"></div>

    <app-explorar-servicios></app-explorar-servicios>

    <div class="py-1"></div>


    <div class="pt-5">
        <app-mini-contacto></app-mini-contacto>
    </div>

    <div class="pt-5">
        <app-pie-pagina></app-pie-pagina>
    </div>

</div>
