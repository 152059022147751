import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-explorar-servicios',
  templateUrl: './explorar-servicios.component.html',
  styleUrls: ['./explorar-servicios.component.scss'],
})
export class ExplorarServiciosComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  goTo(url) {
    this.router.navigateByUrl(url);
  }
}
