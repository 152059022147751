<app-navbar></app-navbar>

<div class="conta" style="overflow: hidden;">

    <div class="jumbotron jumbotron-fluid jumbotronCustom">
        <div>
            <img class="responsiveImg" src="\assets\services\contact-us\close-up-of-male-hands-using-l.png" alt=" ">
        </div>
    </div>

    <div class="container-text">
        <h1 class="titleContactUs"> <br> <br> <br> CONTACT US</h1> <br> <br>
    </div>

    <div class="jumbotron jumbotron-fluid pt-5 img-ct">
        <div class="row py-5 mh">
            <div class="col-6"><img class="manImg" src="\assets\services\contact-us\pexels-andrea-piacquadio-84545.png" alt=""></div>
            <div class="col-6 py-3 px-5 d-flex align-items-center containerUnderImage">
                <p class="textUnderImage"> Thank you for your interest in AAA+. Our team is built up of experts in the formation, administration and management of various offshore corporate, financial and legal structures. <br> <br> Let us know how we can assist you, and we will
                    get in touch shortly.
                </p>
            </div>

        </div>
        <div class="jumbotron jumbotron-fluid containerContact py-5 ">
            <form #contactForm="ngForm" (ngSubmit)="sendContact()">
                <div class="container-contact-us">
                    <h2 class="titleInputsContact">Contact Us</h2>
                    <div class="row ">
                        <div class="col-12 py-4 ">
                            <p class="titleInput">Name</p>
                            <input type="text" name="name" class="form-control" id="Name" [(ngModel)]="contact.name" #name="ngModel" required autocomplete="name">
                        </div>
                        <div class="col-12 py-4 ">
                            <p class="titleInput ">E-mail</p>
                            <input type="email" name="email" id="email" class="form-control" [(ngModel)]="contact.email" #email="ngModel" required autocomplete="email">
                        </div>
                        <div class="col-12 py-4 ">
                            <p class="titleInput ">Subject</p>
                            <input type="text" name="subject" id="subject" class="form-control" [(ngModel)]="contact.subject" #subject="ngModel" required autocomplete="subject">
                        </div>
                        <div class="col-12 py-4 ">
                            <p class="titleInput ">Message</p>
                            <!-- <input placeholder="Message " type="text " class=" "> -->
                            <textarea class="form-control" id="message" name="message" aria-label="With textarea" [(ngModel)]="contact.message" #message="ngModel"></textarea>
                        </div>
                    </div>
                    <div class="cap-di">
                        <re-captcha (resolved)="resolved($event)" siteKey="6Lcx3tMaAAAAAHGdcs3kgzb3O8q4fOJ700cld2dh">
                        </re-captcha>
                    </div>

                    <div class="centerButton py-5">
                        <button type="submit" class="btn btn-outline alignCenter btn-sen" [disabled]="isSending || !validated">Send</button>
                    </div>
                </div>
            </form>

        </div>

        <div class="pt-5 fot-mob">
            <app-footer></app-footer>
        </div>

    </div>