<app-menu></app-menu>
<div class="abt" style="overflow: hidden;">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
            <source src="\assets\services\About us\AAA+ Banner - About Us 01.mp4" type="video/mp4">
        </video>
    </div>

    <div class="container pt-5 pb-1">

        <h1 class="corporateTitle fontMontserrat-SemiBold">ACERCA DE NOSOTROS</h1>
        <div class="containerHeader">
            <h3 class="corporateSubtitle fontMontserratMedium">
              El arte de preservar tu legado
            </h3>
        </div>
        <div class="container-text">
            <p class="corporateText ">Fundado en 2010,  <strong>AAA+</strong> Wealth Management Advisors, es una Firma independiente que presta servicios a empresas, familias de alto poder adquisitivo y empresarios de todo el mundo. <strong>AAA+</strong> brinda asesoría​ para la formación de estructuras corporativas
                offshore y los complementos legales y financieros para que pueda sobresalir en un mundo altamente competitivo y regulado.</p>
            <p class="corporateText "> En el caso de empresas familiares, <strong>AAA+</strong> ofrece las soluciones necesarias para el manejo de patrimonio y gestión patrimonial para proteger a los miembros de la familia y sus activos. Además, buscamos oportunidades para que las empresas puedan
                crecer con mayor eficiencia.</p>
            <p class="corporateText "> Nuestro proceso de orientación comienza con usted. Nos enfocamos en el éxito que a lo largo de su vida con arduo trabajo ha logrado obtener. Tomando esto en cuenta nos comprometemos a ayudarle a alcanzar sus objetivos financieros. </p>
            <p class="corporateText "> En <strong>AAA+</strong>, diseñamos un plan a la medida, el cual evoluciona constantemente, ayudándolo a permanecer eficiente mientras aseguramos que las necesidades de su familia y negocio, están siendo atendidas con la máxima calidad. </p>
        </div>

    </div>

    <div class="jumbotron jumbotron-fluid jumbotron-blue py-5 conf">

        <!-- <div class="container py-3">
            <div class="row">
              <div class="col-12 ">
                <h2 class="confidentialityTitle text-center">
                  Comprometidos con:
                </h2>
              </div>
            </div>
            <div class="row py-5 mh">
                <div class="col-3">
                    <img class=" svgText img-ca rounded mx-auto d-block" src="\assets\cambios\Nosotros-Confidencialidad_e_Integridad\Integridad.jpg" alt="">
                </div>
                <div class="col">
                    <h3 class="confidentialityTitle ">
                       INTEGRIDAD
                    </h3>
                    <p class="confidentialityText">

                        Trabajamos para aumentar la reputación de <strong>AAA+</strong> y de nuestro personal. Nuestros asesores con altos valores morales y éticos, trabajan para mantener relaciones sólidas y a largo plazo con nuestros clientes.
                    </p>
                </div>
            </div>

            <div class="row pt-3 mb">
                <div class="col">
                    <h3 class="confidentialityTitle ">
                        CONFIDENCIALIDAD
                    </h3>
                    <p class="confidentialityText">
                      En <strong>AAA+</strong> estamos comprometidos en proteger su confidencialidad, esto se logra al usar canales de comunicación privados y cerrados. Además un único equipo de expertos le dará todo tipo de asesoría y servicios necesarios para alcanzar sus metas.
                    </p>
                </div>
                <div class="col-2">
                    <img class="svgText rounded" src="\assets\cambios\Nosotros-Confidencialidad_e_Integridad\Confidencialidad.jpg" alt="">
                </div>
            </div>
        </div> -->

        <div class="container py-5">
          <div class="text-center mb-5">
              <h2 class="confidentialityTitle text-center">Comprometidos con:</h2>
          </div>
          <div class="row align-items-center mb-5">
              <div class="col-md-4 text-center">
                  <img class="img-fluid rounded" src="\assets\cambios\Nosotros-Confidencialidad_e_Integridad\Integridad.jpg" alt="Integridad">
              </div>
              <div class="col-md-8">
                  <h3 class="confidentialityTitle">INTEGRIDAD</h3>
                  <p class="confidentialityText">
                      Trabajamos para aumentar la reputación de <strong>AAA+</strong> y de nuestro personal. Nuestros asesores con altos valores morales y éticos, trabajan para mantener relaciones sólidas y a largo plazo con nuestros clientes.
                  </p>
              </div>
          </div>
          <div class="row align-items-center">
              <div class="col-md-8">
                  <h3 class="confidentialityTitle">CONFIDENCIALIDAD</h3>
                  <p class="confidentialityText">
                      En <strong>AAA+</strong> estamos comprometidos en proteger su confidencialidad, esto se logra al usar canales de comunicación privados y cerrados. Además, un único equipo de expertos le dará todo tipo de asesoría y servicios necesarios para alcanzar sus metas.
                  </p>
              </div>
              <div class="col-md-4 text-center">
                  <img class="img-fluid rounded" src="\assets\cambios\Nosotros-Confidencialidad_e_Integridad\Confidencialidad.jpg" alt="Confidencialidad">
              </div>
          </div>
      </div>
    </div>

    <!-- <div class="jumbotron jumbotron-fluid jumbotron-video py-5">
        <video class="video" loop autoplay>
          <source src="\assets\services\About us\AAA+ Banner - About Us 01.mp4" type="video/mp4">
          </video>
    </div> -->

    <!-- <br> <br> <br> -->

    <div class="pt-5">
        <app-mini-contacto></app-mini-contacto>
    </div>

    <div class="pt-5">
        <app-pie-pagina></app-pie-pagina>
    </div>
</div>
