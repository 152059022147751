import { Component, OnInit } from '@angular/core';

import { Meta, Title} from '@angular/platform-browser';
@Component({
  selector: 'app-legal-advice',
  templateUrl: './legal-advice.component.html',
  styleUrls: ['./legal-advice.component.scss']
})
export class LegalAdviceComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.title.setTitle(
      ' Services | Triple A+ '
    );
    this.meta.updateTag({
      name: 'og:image',
      content: 'https://syr.us/l5e'
    });
    this.meta.updateTag({
      name: 'og:descripcion',
      content: 'Triple A+, can offer to you years of experience in Corporate Offshore Planning, Wealth Management, US Bank account opening, Finance, Legal Advice and Tax planning '
    });
    //Se  siguen agregando mas this.meta para las diferentes meta tags como keywords, url, image, etc
    this.meta.updateTag({
      name: 'keywords',
      content: 'About Triple AAA Plus, About AAA+'
    });
    this.meta.updateTag({
      name: 'og:url',
      content: 'https://tripleaaaplus.com/services'
    });
    //Se  siguen agregando mas this.meta para las diferentes meta tags como keywords, url, image, etc
  }

}
