import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';

import { ServiceComponent } from './components/services/service/service.component';
import { AccountingAndAuditingComponent } from './components/services/accounting-and-auditing/accounting-and-auditing.component';
import { FinanceComponent } from './components/services/finance/finance.component';
import { LegalAdviceComponent } from './components/services/legal-advice/legal-advice.component';
import { RealStateInvestmentComponent } from './components/services/real-state-investment/real-state-investment.component';
import { BankComponent } from './components/services/bank/bank.component';
import { WealthManagementComponent } from './components/services/wealth-management/wealth-management.component';
import { ContatUsComponent } from './components/contat-us/contat-us.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { InicioComponent } from './componentes/inicio/inicio.component';
import { MenuComponent } from './componentes/menu/menu.component';
import { ContactanosComponent } from './componentes/contactanos/contactanos.component';
import { ServicioComponent } from './componentes/servicios/servicio/servicio.component';
import { PoliticaCookiesComponent } from './componentes/politica-cookies/politica-cookies.component';
import { PlanificacionCorporativaOffshoreComponent } from './componentes/servicios/planificacion-corporativa-offshore/planificacion-corporativa-offshore.component';
import { FinanzasComponent } from './componentes/servicios/finanzas/finanzas.component';

import { AcercaDeAaaplusComponent } from './componentes/acerca-de-aaaplus/acerca-de-aaaplus.component';
import { AperturaDeCuentasBancariasComponent } from './componentes/servicios/apertura-de-cuentas-bancarias/apertura-de-cuentas-bancarias.component';
import { ContabilidadYAuditoriaComponent } from './componentes/servicios/contabilidad-y-auditoria/contabilidad-y-auditoria.component';
import { GestionPatrimonialComponent } from './componentes/servicios/gestion-patrimonial/gestion-patrimonial.component';
import { AsesoriaLegalYPlanificacionFiscalComponent } from './componentes/servicios/asesoria-legal-y-planificacion-fiscal/asesoria-legal-y-planificacion-fiscal.component';
import { InversionInmobiliariaComponent } from './componentes/servicios/inversion-inmobiliaria/inversion-inmobiliaria.component';
import { CorporateOffshorePlanningComponent } from './components/services/corporate-offshore-planning/corporate-offshore-planning.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContatUsComponent },
  { path: 'real-state-investment', component: RealStateInvestmentComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },

  { path: 'services', component: ServiceComponent },
  {
    path: 'services/corporate-offshore-planning',
    component: CorporateOffshorePlanningComponent,
  },
  { path: 'services/bank-account-opening', component: BankComponent },
  {
    path: 'services/account-and-auditing',
    component: AccountingAndAuditingComponent,
  },
  { path: 'services/finance', component: FinanceComponent },
  {
    path: 'services/legal-advice-and-tax-planning',
    component: LegalAdviceComponent,
  },
  {
    path: 'services/wealth-management-and-investments',
    component: WealthManagementComponent,
  },
  { path: 'footer', component: FooterComponent } /*  */,
  { path: 'navbar', component: NavbarComponent },

  { path: '', redirectTo: '/home', pathMatch: 'full' },

  { path: 'inicio', component: InicioComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'contactanos', component: ContactanosComponent },
  { path: 'acerca-de-aaaplus', component: AcercaDeAaaplusComponent },
  { path: 'politica-cookies', component: PoliticaCookiesComponent },
  { path: 'servicios', component: ServicioComponent },
  {
    path: 'servicios/planificacion-corporativa-offshore',
    component: PlanificacionCorporativaOffshoreComponent,
  },
  {
    path: 'servicios/apertura-de-cuentas-bancarias',
    component: AperturaDeCuentasBancariasComponent,
  },
  {
    path: 'servicios/contabilidad-y-Auditoria',
    component: ContabilidadYAuditoriaComponent,
  },
  { path: 'servicios/finanzas', component: FinanzasComponent },
  {
    path: 'servicios/gestion-patrimonial',
    component: GestionPatrimonialComponent,
  },
  {
    path: 'servicios/asesoria-legal-y-planificacion-fiscal',
    component: AsesoriaLegalYPlanificacionFiscalComponent,
  },
  { path: 'servicios/servicio', component: ServiceComponent },
  {
    path: 'inversion-inmobiliaria',
    component: InversionInmobiliariaComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
