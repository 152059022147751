<app-menu></app-menu>
<div class="legal">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
            <source src="\assets\services\legal-advice\AAA+ Banner - Legal Advice and Tax Planning.mp4"
                type="video/mp4">
        </video>
    </div>


    <div class="container py-4">
        <h1 class="mainTitle pt-2 pb-4">ASESORÍA LEGAL Y <br> PLANIFICACIÓN TRIBUTARIA</h1>

        <div class="row">
            <div class="col-3">
                <div class="col">
                    <img class="svgImage" src="\assets\services\legal-advice\svg\HLM Artboard 35.svg " alt=" ">
                </div>
            </div>

            <div class="col-9 d-flex align-items-center">
                <p class="mainText container-text-legal centerDiv ">
                    AAA+ trabaja de la mano con bufetes de abogados y firmas de contabilidad y auditoría de todo el mundo para brindar soluciones y cumplir con todas las complejidades y regulaciones alrededor del mundo. A través de estas alianzas, podemos encontrar las mejores
                    soluciones a sus necesidades.

                </p>
            </div>
        </div>
    </div>

    <div class="cut">
        <div class="py-5 ">
            <app-explorar-servicios></app-explorar-servicios>
        </div>

        <div class="pt-3">
            <app-mini-contacto></app-mini-contacto>
        </div>

        <div class="pt-5">
            <app-pie-pagina></app-pie-pagina>
        </div>
    </div>


</div>