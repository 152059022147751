<app-navbar></app-navbar>
<div class="legal">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
            <source src="\assets\services\legal-advice\AAA+ Banner - Legal Advice and Tax Planning.mp4"
                type="video/mp4">
        </video>
    </div>


    <div class="container py-4">
        <h1 class="mainTitle pt-2 pb-4">Legal Advice & <br> Tax Planning</h1>

        <div class="row">
            <div class="col-3">
                <div class="col">
                    <img class="svgImage" src="\assets\services\legal-advice\svg\HLM Artboard 35.svg " alt=" ">
                </div>
            </div>

            <div class="col-9 d-flex align-items-center">
                <p class="mainText container-text-legal centerDiv ">
                    AAA+ works hand in hand with law and accounting firms from all over the world to provide solutions and comply with all the complexities and regulations around the world. Through these collaborations we are able to find the best solutions for your needs.
                </p>
            </div>
        </div>
    </div>

    <div class="cut">
        <div class="py-5 ">
            <app-explore-our-services></app-explore-our-services>
        </div>

        <div class="pt-3">
            <app-mini-contact-us></app-mini-contact-us>
        </div>

        <div class="pt-5">
            <app-footer></app-footer>
        </div>
    </div>


</div>