<app-menu></app-menu>
<div class="fin">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
    <source src="\assets\services\finances\AAA+ Banner - Finance.mp4" type="video/mp4">
    </video>
    </div>

    <div class="container-content pb-5 fin-rw">
        <h1 class="mainTitleFinance py-3">FINANZAS</h1>
        <div class="row d-flex-z">
            <div class="col-3 d-flex align-items-center">
                <div class="col svgText"><img class="svgImage" src="\assets\services\finances\svg\HLM Artboard 34.svg" alt=""> </div>

            </div>

            <div class="col mr-auto">
                <p class="mainTextFinance container-text-finance centerDiv">
                    Con más de 10 años de experiencia entendemos las necesidades de crecimiento de las empresas y las oportunidades de crecimiento perdidas debido a la falta de financiamiento. A través de alianzas con empresas externas, podemos impulsar el crecimiento de
                    su empresa, brindando servicios de factoraje, adelantos de cuentas por cobrar, servicios de cobranza, préstamos Lombard, préstamos back-to-back, cuentas de margen, entre otros. Con AAA+ nunca volverá a perder una oportunidad de negocio.
                </p>
            </div>
        </div>

    </div>

    <br>
    <!-- <div class="jumbotron jumbotron-fluid jumbotron-video py-5">
    <video class="video" loop autoplay>
    <source src="\assets\services\About us\AAA+ Banner - About Us 01.mp4" type="video/mp4">
    </video>
</div> -->



    <div class="pb-5">
        <app-explorar-servicios></app-explorar-servicios>
    </div>

    <div class="pt-3">
        <app-mini-contacto></app-mini-contacto>
    </div>

    <div class="pt-5">
        <app-pie-pagina></app-pie-pagina>
    </div>

</div>