<app-menu></app-menu>

<div class="homepage">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" loop autoplay playsinline preload="auto">
            <source class="" src="\assets\home\AAA+ Banner - Home.mp4" type="video/mp4">
        </video>
        <!--   <video id="video1" width="420" loop autoplay controls>
            <source class="" src="\assets\home\AAA+ Banner - Home.mp4" type="video/mp4">
        </video>
        <iframe src="\assets\home\AAA+ Banner - Home.mp4" title="video" width="100%"></iframe> -->
    </div>


    <div class="container pt-5 customT">
        <h1 class="mainTitleBlack pt-3">Bienvenidos a</h1>
        <br>
        <img src="\assets\cambios\logo\Logo AAA - Transparente Color.png" class="rounded mx-auto d-block" alt="..." style="height: 100px;">
        <br>
        <h3 class="mainSubTitle">"El arte de preservar tu legado"</h3>
        <h4 class=" py-4 subTextWelc">
            Nuestro equipo especializado de asesores tiene una amplia experiencia en la creación de empresas, apertura de cuentas bancarias, contabilidad, auditoría, gestión patrimonial, inversiones inmobiliarias, asesoría legal y planificación fiscal.
        </h4>
        <!-- <button class="btn-custom"> <a routerLink="/contactanos">¿Cómo podemos ayudarle?</a></button> -->
    </div>

    <div class="exp-men">
        <app-explorar-servicios></app-explorar-servicios>
    </div>
    <br><br><br><br>
    <div class="container pt-5 customT">



        <div class="row pt-5 sec-par">
            <div class="col-12 col-md-6 pt-3">

                <h3 class="weOperateGlobalyText pb-3">OPERAMOS GLOBALMENTE</h3>

                <div class="container-text">
                    <h4 class="subText no py-4">
                      En un mundo desafiante y constantemente cambiante, se tienen que encontrar formas eficientes para administrar su negocio y proteger sus activos eficazmente.
                    </h4>
                </div>
                <br><br>

                <div id="numbersDiv" class="col-sm-12 col-md-6 containerNumbers">
                    <div style="text-align: center;" class="row">
                        <!-- <div class="col">
                            <p class="numberText"><span class="contador"></span></p>
                            <p class="underNumberText pt-4">Países</p>
                        </div>
                        <div class="col">
                            <p class="numberText"><span class="contador1"></span>+</p>
                            <p class="underNumberText pt-4">Clientes a <br> nivel mundial</p>
                        </div> -->

                        <div class="col">
                            <p class="numberText">+<span class="contador2"></span></p>
                            <p class="underNumberText pt-4">Años operando y asesorando a clientes en todos los continentes.</p>
                        </div>
                    </div>
                    <div class="row">
                    </div>
                </div>



            </div>
            <div class="col-sm-12 col-md-6" style="text-align: center;">
                <img class="worldStyle" src="\assets\cambios\final_mundo.gif" alt="" style="width: 300px;">
                <!-- <video class="responsiveVideoWorld" loop autoplay>
                        <source class="" src="\assets\home\mapa mundi.mov" type="video/quicktime">
                      </video> -->
            </div>
        </div>

        <br><br><br><br>


    </div>
    <div class="cintillo">
        <div class="izquierda">
            <h2>SOMOS EXPERTOS EN CREAR EMPRESAS OFFSHORE</h2>
            <!-- <h4>SOMOS EXPERTOS EN CREAR EMPRESAS OFFSHORE</h4> -->
        </div>
        <div class="derecha">
            <a routerLink="/servicios/planificacion-corporativa-offshore">Más información</a>
        </div>
    </div>


    <div class="jumbotron jumbotron-fluid jumbotron-grey pt-5 les-tp">

      <h2 class="titleMiniCards pb-1">

        <strong>AAA+</strong> PROPORCIONA SOLUCIONES <br> A LOS SIGUIENTES CLIENTES
      </h2>
        <!--
        <div class="container pt-5">
            <div class="row d-flex-z ">
                <div class="col d-flex">
                    <div class="card mini-card-container">
                        <div class="row">

                            <div class="container-img-mini-card ">
                                <img class="imgMiniCard " src="\assets\home\svg\HLM Artboard 9.svg" alt="">
                            </div>


                            <div class="container-mini-card-text">
                                <br><br>
                                <p class="text-mini-card">
                                    Companies involved in foreign trade (importers / exporters and international brokers)
                                    that can become more efficient by using international corporate structures.
                                </p>


                            </div>


                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="col d-flex">
                        <div class="card mini-card-container">
                            <div class="row">

                                <div class="container-img-mini-card ">
                                    <img class="imgMiniCard " src="\assets\home\svg\HLM Artboard 10.svg" alt="">
                                </div>


                                <div class="container-mini-card-text">
                                    <br><br>
                                    <p class="text-mini-card">
                                        Companies or individuals who want to restructure their local and international
                                        operations with a high level of security, confidentiality and efficiency.
                                    </p>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="col d-flex">
                        <div class="card mini-card-container">
                            <div class="row">

                                <div class="container-img-mini-card ">
                                    <img class="imgMiniCard " src="\assets\home\svg\HLM Artboard 11.svg" alt="">
                                </div>


                                <div class="container-mini-card-text">
                                    <br><br>
                                    <p class="text-mini-card">
                                        Families seeking advice on asset management and protection as well as efficient
                                        succession planning through generations.
                                    </p>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

     -->
        <!-- Flickity Desktop HTML init -->
        <div class="carousel desk-car" data-flickity='{ "wrapAround": true }'>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col d-flex">
                            <div class="card mini-card-container">
                                <div class="row">

                                    <div class="container-img-mini-card ">
                                        <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\01.png" alt="">
                                    </div>


                                    <div class="container-mini-card-text">
                                        <br><br>
                                        <p class="text-mini-card">
                                            Compañías dedicadas al comercio exterior (importadores/exportadores y brokers internacionales) que pueden volverse más eficientes utilizando estructuras corporativas offshore.
                                        </p>


                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                            <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\02.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">

                                                Familias que desean asesoría en la planificación, manejo, protección y traspaso eficiente de su patrimonio a través de futuras generaciones.

                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                            <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\03.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Empresas o personas que requieren financiamiento o apalancamiento para hacer crecer su negocio.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col d-flex">
                            <div class="card mini-card-container">
                                <div class="row">

                                    <div class="container-img-mini-card ">
                                        <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\04.png" alt="">
                                    </div>


                                    <div class="container-mini-card-text">
                                        <br><br>
                                        <p class="text-mini-card">
                                            Empresas o personas que deseen optimizar sus inversiones utilizando opciones seguras y sofisticadas disponibles en los mercados financieros internacionales.
                                        </p>


                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                            <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\05.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Empresas dedicadas al comercio electrónico; ofrecemos soluciones para ventas en línea y procesamiento de pagos.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                            <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\06.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Empresas o personas que requieren servicios legales y/o contables más allá de sus propias fronteras.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col d-flex">
                            <div class="card mini-card-container">
                                <div class="row">

                                    <div class="container-img-mini-card ">
                                        <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\07.png" alt="">
                                    </div>


                                    <div class="container-mini-card-text">
                                        <br><br>
                                        <p class="text-mini-card">
                                            Compañías o personas en busca de oportunidades de inversión en cualquier parte del mundo. </p>


                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                            <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\08.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Empresas o personas que deseen registrar sus embarcaciones (yates, cruceros, barcos comerciales, etc) y aeronaves (aviones, jets, etc) bajo jurisdicciones favorables.

                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                            <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\09.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Empresas o personas que deseen reestructurar sus operaciones locales e internacionales con un alto nivel de seguridad, confidencialidad y eficiencia.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Flickity Mobile HTML init -->
        <div class="carousel mob-car" data-flickity='{ "wrapAround": true }'>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col d-flex">
                            <div class="card mini-card-container">
                                <div class="row">

                                    <div class="container-img-mini-card ">
                                      <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\01.png" alt="">
                                    </div>


                                    <div class="container-mini-card-text">
                                        <br><br>
                                        <p class="text-mini-card">
                                            Compañías dedicadas al comercio exterior (importadores/exportadores y brokers internacionales) que pueden volverse más eficientes utilizando estructuras corporativas offshore.
                                        </p>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br><br><br>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\02.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Familias que desean asesoría en la planificación, manejo, protección y traspaso eficiente de su patrimonio a través de futuras generaciones.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\03.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Empresas o personas que requieren financiamiento o apalancamiento para hacer crecer su negocio
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\04.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card"> Empresas o personas que deseen optimizar sus inversiones utilizando opciones seguras y sofisticadas disponibles en los mercados financieros internacionales.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\05.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Empresas dedicadas al comercio electrónico; ofrecemos soluciones para ventas en línea y procesamiento de pagos.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\06.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Empresas o personas que requieren servicios legales y/o contables más allá de sus propias fronteras.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="card mini-card-container">
                                        <div class="row">

                                            <div class="container-img-mini-card ">
                                              <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\07.png" alt="">
                                            </div>


                                            <div class="container-mini-card-text">
                                                <br><br>
                                                <p class="text-mini-card">
                                                    Compañías o personas en busca de oportunidades de inversión en cualquier parte del mundo.
                                                </p>


                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\08.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Empresas o personas que deseen registrar sus embarcaciones (yates, cruceros, barcos comerciales, etc) y aeronaves (aviones, jets, etc) bajo jurisdicciones favorables
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\09.png" alt="">


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Empresas o personas que deseen reestructurar sus operaciones locales e internacionales con un alto nivel de seguridad, confidencialidad y eficiencia.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    </div>

    <!-- <div class="card card-container d-flex-z">

        <div class="d-flex row">
            <div class="col-sm-12 col-md-6 container-text-card">
                <p class="textCard">
                    AAA+ proporciona asesoramiento en la formación de estructuras corporativas offshore incluyendo los complementos financieros y legales necesarios para un rendimiento óptimo. Nuestra asesoría profesional ofrece beneficios como: anonimato, confidencialidad,
                    protección de activos, manejo patrimonial eficiente, planificación patrimonial, optimización fiscal, entre otros.

                    <button class="btn-custom btn-get esp"> <a routerLink="/servicios/planificacion-corporativa-offshore">Leer Más</a></button>
                </p>

            </div>
            <div class="col-sm-12 col-md-6 hand-img">
                <img src="\assets\home\cropped-view-of-man-extending-.png" alt="">
            </div>
        </div>
    </div> -->
    <br><br>
    <br><br>

    <div class="pt-5">
        <app-mini-contacto></app-mini-contacto>
    </div>

    <div class="pt-5">
        <app-pie-pagina></app-pie-pagina>
    </div>
</div>
