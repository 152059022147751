<app-navbar></app-navbar>
<div class="acon">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" loop autoplay playsinline preload="auto">
        <source class="" src="\assets\services\accountingAndAuditing\AAA+ Banner - Accounting Auditing.mp4" type="video/mp4">
      </video>
    </div>
    <br>

    <div class="mainTextAccounting pt-5 pb-5">
        <h1 class="mainTitleBlack corporateTitle py-5">Accounting<br> & Auditing</h1>
        <div class="container-text centerDiv">
            <p class="corporateText">As the world evolves towards a more transparent and regulated place, it is vitally important to comply with local and international tax administration regulations. That is why <strong>AAA+</strong> works hand in hand with prestigious Firms around the world, committed to accounting compliance.</p>
        </div>
    </div>

    <br> <br> <br><br>

    <!-- <div class="jumbotron jumbotron-fluid jumbotron-video py-5">
    <video class="video" loop autoplay>
    <source src="\assets\services\About us\AAA+ Banner - About Us 01.mp4" type="video/mp4">
    </video>

</div> -->

    <div class=" pb-5">
        <app-explore-our-services class="pt-5"> </app-explore-our-services>
    </div>

    <div class="pt-3">
        <app-mini-contact-us></app-mini-contact-us>
    </div>

    <div class="pt-5">
        <app-footer></app-footer>
    </div>

</div>
