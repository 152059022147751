<app-navbar>

</app-navbar>


<div class="homepage">
    <div class="modal fade" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="title">
                        <h3>Language Selection</h3>
                        <h3>Selección De Idioma</h3>
                    </div>
                    <div class="img">
                        <img src="assets/world.svg" alt="...">
                    </div>
                    <div class="paragraph">
                        <p>Please select your preferred language to navigate through the site.</p>
                        <p>Por favor seleccione su idioma de preferencia para continuar con la navegación.</p>
                    </div>
                    <div class="buttons">
                        <button class="english" (click)="closeModal()">English</button>
                        <button class="english" (click)="openPageSpanish()">Español</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" loop autoplay playsinline preload="auto">
            <source class="" src="\assets\home\AAA+ Banner - Home.mp4" type="video/mp4">
        </video>
        <!--   <video id="video1" width="420" loop autoplay controls>
            <source class="" src="\assets\home\AAA+ Banner - Home.mp4" type="video/mp4">
        </video>
        <iframe src="\assets\home\AAA+ Banner - Home.mp4" title="video" width="100%"></iframe> -->
    </div>


    <div class="container pt-5 customT">
        <h1 class="mainTitleBlack pt-3">WELCOME TO</h1>
        <br>
        <img src="\assets\cambios\logo\Logo AAA - Transparente Color.png" class="rounded mx-auto d-block" alt="..." style="height: 100px;">
        <br>
        <h3 class="mainSubTitle">“The Art of Preserving your Legacy”</h3>
        <h4 class=" py-4 subTextWelc">
            Our specialized and qualified team of advisors have extensive experience with company formation, bank account opening, accounting, auditing, wealth management, real estate investments, legal advice and tax planning across the globe.
        </h4>
        <!-- <button class="btn-custom"> <a routerLink="/contact-us">HOW CAN WE HELP YOU?</a></button> -->
    </div>

    <div class="exp-men">
        <app-explore-our-services></app-explore-our-services>
    </div>
    <br><br><br><br>
    <div class="container pt-5 customT">



        <div class="row pt-5 sec-par">
            <div class="col-12 col-md-6 pt-3">

                <h3 class="weOperateGlobalyText pb-3">We operate Globaly</h3>

                <div class="container-text">
                    <h4 class="subText no py-4">
                      In a challenging and constantly changing world, you have to find efficient ways to manage your business and protect your assets effectively.
                    </h4>
                </div>
                <br><br>

                <div id="numbersDiv" class="col-sm-12 col-md-6 containerNumbers">
                    <div style="text-align: center;" class="row">
                        <!-- <div class="col">
                            <p class="numberText"><span class="contador"></span></p>
                            <p class="underNumberText pt-4">Countries</p>
                        </div>
                        <div class="col">
                            <p class="numberText"><span class="contador1"></span>+</p>
                            <p class="underNumberText pt-4">Worldwide <br> clients</p>
                        </div> -->

                        <div class="col">
                            <p class="numberText">+<span class="contador2"></span></p>
                            <p class="underNumberText pt-4">Years operating and advising clients on all continents.</p>
                        </div>
                    </div>
                    <div class="row">
                    </div>
                </div>



            </div>
            <div class="col-sm-12 col-md-6" style="text-align: center;">
              <img class="worldStyle" src="\assets\cambios\final_mundo.gif" alt="" style="width: 300px;">
              <!-- <video class="responsiveVideoWorld" loop autoplay>
                      <source class="" src="\assets\home\mapa mundi.mov" type="video/quicktime">
                    </video> -->
            </div>
        </div>

        <br><br><br><br>


    </div>
    <div class="cintillo">
        <div class="izquierda">
            <h2>WE ARE EXPERTS IN CREATING OFFSHORE COMPANIES</h2>
            <!-- <h4>Our team of qualified advisors are ready to assist you.</h4> -->
        </div>
        <div class="derecha">
            <a routerLink="/services/corporate-offshore-planning">Learn more</a>
        </div>
    </div>


    <div class="jumbotron jumbotron-fluid jumbotron-grey pt-5 les-tp">

        <h2 class="titleMiniCards pb-1">

          <strong>AAA+</strong> PROVIDES SOLUTIONS <br> TO THE FOLLOWING CUSTOMERS</h2>
        <!--
        <div class="container pt-5">
            <div class="row d-flex-z ">
                <div class="col d-flex">
                    <div class="card mini-card-container">
                        <div class="row">
                            <div class="container-img-mini-card ">
                                <img class="imgMiniCard " src="\assets\home\svg\HLM Artboard 9.svg" alt="">
                            </div>
                            <div class="container-mini-card-text">
                                <br><br>
                                <p class="text-mini-card">
                                    Companies involved in foreign trade (importers / exporters and international brokers)
                                    that can become more efficient by using international corporate structures.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="col d-flex">
                        <div class="card mini-card-container">
                            <div class="row">
                                <div class="container-img-mini-card ">
                                    <img class="imgMiniCard " src="\assets\home\svg\HLM Artboard 10.svg" alt="">
                                </div>
                                <div class="container-mini-card-text">
                                    <br><br>
                                    <p class="text-mini-card">
                                        Companies or individuals who want to restructure their local and international
                                        operations with a high level of security, confidentiality and efficiency.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="col d-flex">
                        <div class="card mini-card-container">
                            <div class="row">
                                <div class="container-img-mini-card ">
                                    <img class="imgMiniCard " src="\assets\home\svg\HLM Artboard 11.svg" alt="">
                                </div>
                                <div class="container-mini-card-text">
                                    <br><br>
                                    <p class="text-mini-card">
                                        Families seeking advice on asset management and protection as well as efficient
                                        succession planning through generations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     -->
        <!-- Flickity Desktop HTML init -->
        <div class="carousel desk-car" data-flickity='{ "wrapAround": true }'>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col d-flex">
                            <div class="card mini-card-container">
                                <div class="row">

                                    <div class="container-img-mini-card ">
                                      <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\01.png" alt="">
                                    </div>


                                    <div class="container-mini-card-text">
                                        <br><br>
                                        <p class="text-mini-card">
                                            Companies involved in foreign trade (importers / exporters and international brokers) that can become more efficient by using offshore corporate structures.
                                        </p>


                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\02.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Families seeking advice on asset management and protection as well as efficient succession planning across generations.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\03.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Companies or individuals that require financing or leveraging of assets for financial growth.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col d-flex">
                            <div class="card mini-card-container">
                                <div class="row">

                                    <div class="container-img-mini-card ">
                                      <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\04.png" alt="">
                                    </div>


                                    <div class="container-mini-card-text">
                                        <br><br>
                                        <p class="text-mini-card">
                                            Companies or individuals that would like to optimize their investments using secure and sophisticated options through international financial markets.
                                        </p>


                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\05.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Companies involved in e-commerce, seeking solutions for online sales and payment processing.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\06.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Companies or individuals that require comprehensive legal and/or accounting services beyond their own borders.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col d-flex">
                            <div class="card mini-card-container">
                                <div class="row">

                                    <div class="container-img-mini-card ">
                                      <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\07.png" alt="">
                                    </div>


                                    <div class="container-mini-card-text">
                                        <br><br>
                                        <p class="text-mini-card">
                                            Companies or individuals seeking investment opportunities across the globe.
                                        </p>


                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\08.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                People or companies that would like to register their boats (yachts, cruise ships, commercial ships, etc.) and aircrafts (airplanes, jets, etc) under favorable jurisdictions.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\09.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Companies or individuals who want to restructure their local and international operations with a high level of security, confidentiality and efficiency.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Flickity Mobile HTML init -->
        <div class="carousel mob-car" data-flickity='{ "wrapAround": true }'>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col d-flex">
                            <div class="card mini-card-container">
                                <div class="row">

                                    <div class="container-img-mini-card ">
                                      <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\01.png" alt="">
                                    </div>


                                    <div class="container-mini-card-text">
                                        <br><br>
                                        <p class="text-mini-card">
                                            Companies involved in foreign trade (importers / exporters and international brokers) that can become more efficient by using offshore corporate structures.
                                        </p>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br><br><br>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\02.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Families seeking advice on asset management and protection as well as efficient succession planning across generations.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\03.png" alt="">
                                        </div>



                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Companies or individuals that require financing or leveraging of assets for financial growth.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\04.png" alt="">
                                        </div>

                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card"> Companies or individuals that would like to optimize their investments using secure and sophisticated options through international financial markets.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\05.png" alt="">
                                        </div>

                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Companies involved in e-commerce, seeking solutions for online sales and payment processing.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\06.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Companies or individuals that require comprehensive legal and/or accounting services beyond their own borders.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="card mini-card-container">
                                        <div class="row">

                                            <div class="container-img-mini-card ">
                                              <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\07.png" alt="">
                                            </div>


                                            <div class="container-mini-card-text">
                                                <br><br>
                                                <p class="text-mini-card">
                                                    Companies or individuals seeking investment opportunities across the globe.
                                                </p>


                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\08.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                People or companies that would like to register their boats (yachts, cruise ships, commercial ships, etc.) and aircrafts (airplanes, jets, etc) under favorable jurisdictions
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="container pt-5">
                    <div class="row d-flex-z ">
                        <div class="col">
                            <div class="col d-flex">
                                <div class="card mini-card-container">
                                    <div class="row">

                                        <div class="container-img-mini-card ">
                                          <img class="imgMiniCard " src="\assets\cambios\Inicio-AAA+Proporciona_soluciones_a_los_siguientes_clientes\09.png" alt="">
                                        </div>


                                        <div class="container-mini-card-text">
                                            <br><br>
                                            <p class="text-mini-card">
                                                Companies or individuals who want to restructure their local and international operations with a high level of security, confidentiality and efficiency.
                                            </p>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    </div>

    <!-- <div class="card card-container d-flex-z">

        <div class="d-flex row">
            <div class="col-sm-12 col-md-6 container-text-card">
                <p class="textCard">
                    AAA+ provides guidance in the formation of international corporate structures, including the financial and legal complements necessary for optimal performance. Our professional guidance offers benefits such as: anonymity, confidentiality, asset protection,
                    efficient wealth management, estate planning, and fiscal optimization, among others.
                    <button class="btn-custom btn-get esp"> <a routerLink="/services/corporate-offshore-planning">Learn More</a></button>
                </p>

            </div>
            <div class="col-sm-12 col-md-6 hand-img">
                <img src="\assets\home\cropped-view-of-man-extending-.png" alt="">
            </div>
        </div>
    </div> -->
    <br><br>
    <br><br>

    <div class="pt-5">
        <app-mini-contact-us></app-mini-contact-us>
    </div>

    <div class="pt-5">
        <app-footer></app-footer>
    </div>
</div>
