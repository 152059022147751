<app-navbar></app-navbar>
<div class="rel">
  <div class="jumbotron jumbotron-fluid jumbotron-custom pb-5">
    <video
      class="responsiveVideo"
      width="320"
      height="240"
      loop
      autoplay
      playsinline
      preload="auto"
    >
      <source
        src="\assets\services\Real states\AAA+ Banner - Real Estate 01.mp4"
        type="video/mp4"
      />
    </video>
  </div>

  <h1 class="mainTitleBlack pt-5">real estate investment</h1>
  <div class="container-main">
    <h3 class="mainSubTitle">
      Real estate investing, even on a very small scale, remains a
      tried-and-true means of building an individual’s cash flow and wealth.
    </h3>
    <br />
    <br />
    <br />
    <p class="corporateText container-text centerDiv">
      Due to the needs and requests from clients, we have set up a Real Estate
      Investment division that provides real estate development projects,
      primarily in South Florida. <br />
      <br />
      AAA+ makes investing in real estate projects easy for you. AAA+ manages
      the process from the beginning: setting up the company, finding a great
      property to develop, getting the plans and permits, construction, sale and
      taxes; you don’t have to worry about anything! Investing in these projects
      has provided many clients with great returns and diversification while
      holding part of their estate in secure assets such as properties in the
      USA. <br /><br />
      For more information, please visit
      <span
        ><a href="www.vcvirtus.com" target="_blank"
          >www.vcvirtus.com</a
        ></span
      ><br />
      <br />
    </p>

    <h2 class="container-text centerDiv projectsText">PROJECTS</h2>
  </div>

  <div class="jumbotron jumbotron-fluid py-5">
    <div class="row py-5 mb">
      <div class="col-7 ng">
        <a
          href="
                http://www.focusdevelopmentusa.com/proyectos/2933-2/"
          target="_blank"
          ><img
            class="manImg w-80"
            src="\assets\services\Real states\ID03-Fachada-scaled.png"
            alt=""
        /></a>
      </div>
      <div class="text-center mt-3">
        <a
          href="
                http://www.focusdevelopmentusa.com/proyectos/2933-2/"
          target="_blank"
        >

            2933 SW 21st Ter, Miami, FL, 33145

        </a>
      </div>
    </div>
  </div>
  <div class="jumbotron jumbotron-fluid jumbotron-grey py-5">
    <div class="row mh">
      <div class="text-center mt-3">
        <a
          href="http://www.focusdevelopmentusa.com/proyectos/3660-2/"
          target="_blank"
        >
          3660 SW 12th Street, Miami, FL, 33135
        </a>
      </div>
      <div class="col-6">
        <a
          href="
                http://www.focusdevelopmentusa.com/proyectos/3660-2/
                "
          target="_blank"
        >
          <img
            class="manImg"
            src="\assets\services\Real states\ID1-Fachada-1-scaled.png"
            alt=""
        /></a>
      </div>
    </div>
  </div>

  <div class="jumbotron jumbotron-fluid py-5 thr-ju">
    <div class="row py-5 mb">
      <div class="col-6">
        <a
          href="http://www.focusdevelopmentusa.com/proyectos/10603-2/"
          target="_blank"
        >
          <img
            class="manImg"
            src="\assets\services\Real states\ID02-FP-scaled (1).png"
            alt=""
          />
        </a>
      </div>
      <div class="col-6 centerDiv py-3 px-5 d-flex align-items-center">
        <a
          href="http://www.focusdevelopmentusa.com/proyectos/10603-2/"
          target="_blank"
        >
          <p class="textTeam centerDiv align-Left py-5">
            10603 NE 11 ave, Miami Shores, FL, 33138
          </p>
        </a>
      </div>
    </div>
  </div>

  <div class="py-0">
    <app-explore-our-services></app-explore-our-services>
  </div>
  <div class="cintillo">
    <div class="izquierda">
      <h2>Interested in AAA+ services?</h2>
      <h4>Our team of qualified advisors.</h4>
    </div>
    <div class="derecha">
      <a href="https://calendly.com/aaawma" target="_blank">Book a call</a>
    </div>
  </div>
  <div class="pt-3">
    <app-mini-contact-us></app-mini-contact-us>
  </div>

  <div class="pt-5">
    <app-footer></app-footer>
  </div>
</div>
