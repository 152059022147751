<div class="jumbotron jumbotron-fluid jumbotron-grey centerContent">
  <br>
  <div class="row pt-5 justify-content-center">
      <h1 class="exploreTitle py-2 pb-5">Explore our services</h1>

      <div class="py-4"></div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/services/corporate-offshore-planning')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top rounded-image" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Planificación corporativa.jpg" alt="Corporate offshore planning">
          </div>
          <p class="pt-3">Corporate offshore planning</p>
      </div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/services/bank-account-opening')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top rounded-image" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Apertura de cuentas bancarias.jpg" alt="Bank account opening">
          </div>
          <p class="pt-3">Bank account opening</p>
      </div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/services/account-and-auditing')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top rounded-image" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Contabilidad.jpg" alt="Accounting / Auditing">
          </div>
          <p class="pt-3">Accounting / Auditing</p>
      </div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/services/wealth-management-and-investments')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top rounded-image" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Gestión patrimonial.jpg" alt="Wealth management">
          </div>
          <p class="pt-3">Wealth management</p>
      </div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/services/finance')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top rounded-image" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Finanzas.jpg" alt="Finance">
          </div>
          <p class="pt-3">Finance</p>
      </div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/services/legal-advice-and-tax-planning')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top rounded-image" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Asesoria legal.jpg" alt="Legal advice & Tax planning">
          </div>
          <p class="pt-3">Legal advice & Tax planning</p>
      </div>
  </div>
  <br><br><br><br>
</div>
