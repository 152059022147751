<app-navbar></app-navbar>
<div class="nank-co">

    <div class="jumbotron jumbotron-fluid jumbotronCustom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
    <source src="\assets\services\bank-account\AAA+ Banner - Bank Account Opening.mp4" type="video/mp4">
    </video>
    </div>

    <div class="container pt-3 pb-3">
        <h1 class="mainTitleBlack corporateTitle py-5"> Offshore Bank Account <br> Opening</h1>
        <p class="mainTextBank corporateText container-text centerDiv">Most entities need a bank account; we can help you in the process of opening an offshore bank account depending on your particular needs. We work with several banks in different jurisdictions; some banks specialize in commercial activities, while
            others cater towards wealth management. Regardless of what you seek, we can provide the right solution. Our reputable and long standing relationships with these financial institutions ensure a smooth onboarding process. Most of these financial
            institutions can open accounts remotely, making the process easy and efficient.

    </div>



    <div class="py-5">
        <app-explore-our-services></app-explore-our-services>
    </div>

    <div class="pt-5">
        <app-mini-contact-us></app-mini-contact-us>
    </div>

    <div class="pt-5">
        <app-footer></app-footer>
    </div>

</div>