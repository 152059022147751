import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';

import { ServiceComponent } from './components/services/service/service.component';
import { ExploreOurServicesComponent } from './components/shared/explore-our-services/explore-our-services.component';
import { MiniContactUsComponent } from './components/shared/mini-contact-us/mini-contact-us.component';
import { AccountingAndAuditingComponent } from './components/services/accounting-and-auditing/accounting-and-auditing.component';
import { FinanceComponent } from './components/services/finance/finance.component';
import { LegalAdviceComponent } from './components/services/legal-advice/legal-advice.component';
import { RealStateInvestmentComponent } from './components/services/real-state-investment/real-state-investment.component';
import { BankComponent } from './components/services/bank/bank.component';
import { WealthManagementComponent } from './components/services/wealth-management/wealth-management.component';
import { ContatUsComponent } from './components/contat-us/contat-us.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';

import { ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MenuComponent } from './componentes/menu/menu.component';
import { InicioComponent } from './componentes/inicio/inicio.component';
import { ContactanosComponent } from './componentes/contactanos/contactanos.component';

import { PoliticaCookiesComponent } from './componentes/politica-cookies/politica-cookies.component';
import { ExplorarServiciosComponent } from './componentes/compartido/explorar-servicios/explorar-servicios.component';
import { MiniContactoComponent } from './componentes/compartido/mini-contacto/mini-contacto.component';
import { PiePaginaComponent } from './componentes/compartido/pie-pagina/pie-pagina.component';

import { FinanzasComponent } from './componentes/servicios/finanzas/finanzas.component';
import { ServicioComponent } from './componentes/servicios/servicio/servicio.component';
import { AcercaDeAaaplusComponent } from './componentes/acerca-de-aaaplus/acerca-de-aaaplus.component';
import { PlanificacionCorporativaOffshoreComponent } from './componentes/servicios/planificacion-corporativa-offshore/planificacion-corporativa-offshore.component';
import { AperturaDeCuentasBancariasComponent } from './componentes/servicios/apertura-de-cuentas-bancarias/apertura-de-cuentas-bancarias.component';
import { ContabilidadYAuditoriaComponent } from './componentes/servicios/contabilidad-y-auditoria/contabilidad-y-auditoria.component';
import { GestionPatrimonialComponent } from './componentes/servicios/gestion-patrimonial/gestion-patrimonial.component';
import { AsesoriaLegalYPlanificacionFiscalComponent } from './componentes/servicios/asesoria-legal-y-planificacion-fiscal/asesoria-legal-y-planificacion-fiscal.component';
import { InversionInmobiliariaComponent } from './componentes/servicios/inversion-inmobiliaria/inversion-inmobiliaria.component';
import { CorporateOffshorePlanningComponent } from './components/services/corporate-offshore-planning/corporate-offshore-planning.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    AboutUsComponent,

    ServiceComponent,
    ExploreOurServicesComponent,
    MiniContactUsComponent,
    AccountingAndAuditingComponent,
    FinanceComponent,
    LegalAdviceComponent,
    RealStateInvestmentComponent,
    BankComponent,
    WealthManagementComponent,
    ContatUsComponent,
    FooterComponent,
    CookiePolicyComponent,
    MenuComponent,
    InicioComponent,
    ContactanosComponent,

    PoliticaCookiesComponent,
    ExplorarServiciosComponent,
    MiniContactoComponent,
    PiePaginaComponent,
    FinanzasComponent,

    ServicioComponent,
    AcercaDeAaaplusComponent,
    PlanificacionCorporativaOffshoreComponent,
    AperturaDeCuentasBancariasComponent,
    ContabilidadYAuditoriaComponent,
    GestionPatrimonialComponent,
    AsesoriaLegalYPlanificacionFiscalComponent,
    InversionInmobiliariaComponent,
    CorporateOffshorePlanningComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
