<app-navbar></app-navbar>
<div class="ser">


    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
            <source src="\assets\services\main\AAA+ Banner - Services 01.mp4" type="video/mp4">
        </video>
    </div>

    <h1 class="mainTitle py-5 customB">OUR SOLUTIONS</h1>


    <div class="row pt-3 customT">
        <div class="col-12 col-sm-6 d-none d-sm-none d-md-block col-img">
            <img class="imgStyle" src="\assets\services\main\two-young-businessman-having-s.png" alt="">
        </div>

        <div class="col-12 col-sm-6 mh">
            <ul type="A" class="textList container-text ">
                <li class="my-3">One-stop-shop solutions for every individual, family and corporations.</li>
                <li class="my-3">Personalized, efficient and outstanding service.</li>
                <li class="my-3">Confidentiality and discretion</li>
                <li class="my-3">Opportunities.</li>
                <li class="my-3">Security and protection of your assets</li>
                <li class="my-3">Backup specially designed for the financial peace of mind of your loved ones</li>
            </ul>
            <br><br>
            <p class="textUnderList container-text centerDiv">
              Our highly qualified advisors will guide you with wealth management strategies to achieve your financial goals, through tailored wealth structures.
            </p>
        </div>
    </div>


    <div class="war-icon">
        <div class="img-war">
            <img src="../../../../assets/footer/HLM Artboard 37.svg" alt="">
        </div>
        <div class="txt-war">
            <p>DUE TO NEEDS AND REQUESTS FROM CLIENTS, WE HAVE SET UP A REAL ESTATE INVESTMENT DIVISION THAT PROVIDES REAL ESTATE DEVELOPMENT PROJECTS PRIMARILY IN SOUTH FLORIDA</p>
        </div>
        <div class="btn-war">
            <button class="btn-custom"> <a routerLink="/contact">LEARN MORE</a></button>
        </div>
    </div>

    <div class="pt-5"></div>

    <app-explore-our-services></app-explore-our-services>

    <div class="py-1"></div>


    <div class="pt-5">
        <app-mini-contact-us></app-mini-contact-us>
    </div>

    <div class="pt-5">
        <app-footer></app-footer>
    </div>

</div>
