import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact } from 'src/app/models/contact.model';
import { ContactService } from 'src/app/services/contact.service';

import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['./contactanos.component.scss'],
  providers: [ContactService],
})
export class ContactanosComponent implements OnInit {
  recaptcha: string;

  contact: Contact;
  status: string;
  isSending = false;
  validated = false;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private title: Title,
    private meta: Meta
  ) {
    this.buildForm();
    this.contact = new Contact('', '', '', '', false);
    this.status = '';
    this.recaptcha = '';
  }

  ngOnInit(): void {
    this.title.setTitle(' Contact Triple A+ ');
    this.meta.updateTag({
      name: 'description',
      content:
        'Triple A+ contact information. 777 Brickell Ave Suite 500 Miami FL, 33131. US Number (305) 420 5768, email info@tripleaaaplus.com',
    });
    this.meta.updateTag({
      name: 'og:descripcion',
      content:
        'Triple A+ contact information. 777 Brickell Ave Suite 500 Miami FL, 33131. US Number (305) 420 5768, email info@tripleaaaplus.com',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'Triple A+ contact information. AAA+ information',
    });
    this.meta.updateTag({
      name: 'og:image',
      content: 'https://syr.us/l5e',
    });
    this.meta.updateTag({
      name: 'og:url',
      content: 'https://tripleaaaplus.com/contact-us',
    });
    //Se  siguen agregando mas this.meta para las diferentes meta tags como keywords, url, image, etc
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }
  save(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      const value = this.form.value;
      console.log(value);
    }
  }

  sendContact(): void {
    if (this.validated) {
      this.isSending = true;
      this.contactService.sendEmail(this.contact).subscribe(
        (res) => {
          this.isSending = false;
          alert('Mensaje de contacto enviado correctamente');
          this.cleanContac();
        },
        (err) => {
          this.isSending = false;
          this.onError(err);
        }
      );
    }
  }

  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
      console.error(errorMessage);
    }
  }

  resolved(captchaResponse: string) {
    this.recaptcha = captchaResponse;
    if (this.recaptcha) {
      this.validated = true;
    }
  }
  cleanContac() {
    this.contact = new Contact('', '', '', '', false);
    this.validated = false;
  }
}
