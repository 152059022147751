import { Component, OnInit } from '@angular/core';

import { Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.title.setTitle(
      ' About Triple A+  '
    );
    this.meta.updateTag({
      name: 'descripcion',
      content: 'Get to know AAA+, a specialized team of Wealth Management Advisors who are willing to assist you in your corporate planning and investment'
    });
    this.meta.updateTag({
      name: 'og:descripcion',
      content: 'Get to know AAA+, a specialized team of Wealth Management Advisors who are willing to assist you today'
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'About Triple AAA Plus, About AAA+'
    });
    this.meta.updateTag({
      name: 'og:image',
      content: 'https://syr.us/l5e'
    });
    this.meta.updateTag({
      name: 'og:url',
      content: 'https://tripleaaaplus.com/about-us'
    });
    //Se  siguen agregando mas this.meta para las diferentes meta tags como keywords, url, image, etc
  }

}
