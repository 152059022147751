<app-menu></app-menu>
<div class="nank-co">

    <div class="jumbotron jumbotron-fluid jumbotronCustom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
    <source src="\assets\services\bank-account\AAA+ Banner - Bank Account Opening.mp4" type="video/mp4">
    </video>
    </div>

    <div class="container pt-3 pb-3">
        <h1 class="mainTitleBlack corporateTitle py-5"> APERTURA DE <br> CUENTAS BANCARIAS</h1>
        <p class="mainTextBank corporateText container-text centerDiv">La mayoría de entidades necesitan una cuenta bancaria; le podemos apoyar en el proceso de apertura de cuenta bancaria en función de sus necesidades. Trabajamos con varios bancos en diferentes jurisdicciones, algunos bancos se especializan en actividades
            comerciales, mientras que otros se especializan en banca privada. Independientemente de lo que usted necesite, podemos ofrecerle la solución adecuada. A lo largo de los años hemos establecido excelentes y duraderas relaciones con diferentes
            instituciones financieras garantizando un proceso de apertura sin problemas. La mayoría de estas instituciones financieras ofrecen apertura de cuentas de forma remota, lo que vuelve el proceso más fácil y eficiente.
    </div>



    <div class="py-5">
        <app-explorar-servicios></app-explorar-servicios>
    </div>

    <div class="pt-5">
        <app-mini-contacto></app-mini-contacto>
    </div>

    <div class="pt-5">
        <app-pie-pagina></app-pie-pagina>
    </div>

</div>