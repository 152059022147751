<div class="jumbotron jumbotron-fluid jumbotron-grey centerContent">
  <br>
  <div class="row pt-5 justify-content-center">
      <h1 class="exploreTitle py-2 pb-5">Explore nuestros servicios</h1>

      <div class="py-4"></div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/servicios/planificacion-corporativa-offshore')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Planificación corporativa.jpg" alt="Planificación corporativa offshore">
          </div>
          <p class="pt-3">Planificación corporativa offshore</p>
      </div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/servicios/apertura-de-cuentas-bancarias')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Apertura de cuentas bancarias.jpg" alt="Apertura de cuentas bancarias">
          </div>
          <p class="pt-3">Apertura de cuentas bancarias</p>
      </div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/servicios/contabilidad-y-Auditoria')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Contabilidad.jpg" alt="Contabilidad / Auditoría">
          </div>
          <p class="pt-3">Contabilidad / Auditoría</p>
      </div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/servicios/gestion-patrimonial')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Gestión patrimonial.jpg" alt="Gestión patrimonial">
          </div>
          <p class="pt-3">Gestión patrimonial</p>
      </div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/servicios/finanzas')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Finanzas.jpg" alt="Finanzas">
          </div>
          <p class="pt-3">Finanzas</p>
      </div>

      <div class="col-md-2 col-6 hand py-2" (click)="goTo('/servicios/asesoria-legal-y-planificacion-fiscal')">
          <div class="card rounded shadow mb-3">
            <img class="svgIcon card-img-top" src="/assets/cambios/Inicio-Explore_nuestros_servicios/Asesoria legal.jpg" alt="Asesoría legal & planificación fiscal">
          </div>
          <p class="pt-3">Asesoría legal & planificación fiscal</p>
      </div>
  </div>
  <br><br><br><br>
</div>
