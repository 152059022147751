<app-navbar></app-navbar>

<div class="container py-5">
    <h1>COOKIE POLICY</h1>
    <h3>AAA+</h3>


    <br>

    <div class="container-text centerDiv">
        <p class=" py-5">

            Effective Date: March 18, 2021​<br><br> TAAA+ respects your concerns about privacy. We obtain certain information by automated means, such as cookies, web server logs, web beacons, and other technologies (collectively “Cookies”) when you visit,
            access, or use our websites, mobile sites or applications, that link to this Cookie Policy (collectively the “Sites”). This Cookie Policy explains how we use Cookies, their purposes, and how you can control them. Our use of Cookies may involve
            the processing of personal information. <br><br> ​Sections include: <br><br> WHAT ARE COOKIES? <br> WHAT INFORMATION DO WE OBTAIN THROUGH COOKIES?<br> COOKIES USED ON OUR SITES <br> Essential Cookies <br> Functional Cookies <br> Analytics
            Cookies <br> Third-party Cookies <br> YOUR CHOICES <br> CONTACT US​ <br><br> WHAT ARE COOKIES?​ <br><br> A “cookie” is a text file that websites send to a visitor’s computer or other Internet-connected device to uniquely identify the visitor’s
            browser or to store information or settings in the browser. This technology helps us (1) remember your information so you will not have to re-enter it; (2) track and understand how you use and interact with the Site; (3) tailor the Site around
            your preferences; (4) measure the usability of the Site; (5) understand the effectiveness of our communications; and (6) otherwise manage and enhance the Site. <br><br> ​WHAT INFORMATION DO WE OBTAIN THROUGH COOKIES?​<br><br> The information
            we obtain through Cookies includes your device IP address, domain name, identifiers associated with your devices, device and operating system type and characteristics, web browser characteristics, language preferences, clickstream data, your
            interactions with the Sites (such as the web pages you visit, links you click and features you use), the pages that led or referred you to the Sites, dates and times of access to the Sites, and other information about your use of the Sites​
            <br><br> COOKIES USED ON OUR SITES <br><br> ​Below is a list of the Cookies that we use on our Sites. You can indicate your choices with respect to the use of Cookies below. Please note that, depending on your selection, you may not be able
            to take full advantage of the Sites or our products and services.​ <br><br> Essential Cookies <br> These Cookies are essential to use and navigate our Sites. Essential Cookies help us collect information, such as your session ID and other
            server information, that enables us to authenticate users. Essential Cookies are deleted when you end your browsing session or within a short period of time thereafter.​ <br><br> Functional Cookies <br> These Cookies collect information about
            your choices or remember your preferences so we can show you relevant content. They allow us to identify your location and remember your language during a browsing session and to customize the Sites you have accessed. These Cookies are deleted
            when you end your browsing session. <br><br> ​Analytics Cookies <br><br> We use analytics Cookies to collect information on how users navigate and use our Sites, such as how the users traverse our Sites, the pages they view, how long they
            stay on a page and whether the page is displayed correctly or whether errors occur. Such cookies help us to improve the performance of our Sites and make the Sites more user-friendly. Some of these Cookies are persistent Cookies which remain
            on your computer or other Internet-connected device for a certain period of time after you end your browsing session unless you delete them. These Cookies are provided by third-party analytics providers, including Google Analytics For more
            information about these third-party providers’ Cookies, please click on the links below: <br> Google Analytics​ <br><br> Preference for Analytics Cookies <br> I accept analytics cookies <br> Yes​ <br><br> Third Party Cookies <br> In some circumstances,
            we work with third parties to provide certain services to our users such as additional features (including the ability to see videos on our Sites) and to connect to certain social networks. These third parties use Cookies to collect information
            on our Sites, including via plug-ins and widgets. Some of these Cookies are persistent Cookies which remain on your computer or other Internet-connected device for a certain period of time after you end your browsing session, unless you delete
            them. <br><br> The privacy practices of these third parties, including Facebook, Instagram, Twitter, Vimeo and LinkedIn are subject to the privacy statements of such third parties, which are available here: <br> Facebook <br> Instagram <br>            Vimeo <br> LinkedIn <br><br> Preference for Third-Party Cookies <br> I accept third party cookies <br> Yes <br><br> ​YOUR CHOICES <br> You can indicate your choice with respect to the use of Cookies. Please note that, depending on your selection,
            you may not be able to take full advantage of the Sites or our products and services. You can stop Cookies from being downloaded on your computer by selecting the appropriate settings on your web browser. Most web browsers will tell you how
            to stop accepting new Cookies, how to be notified when you receive a new Cookie and how to disable existing Cookies. On Microsoft Internet Explorer, this can be done by selecting “Tools/Internet Options” and reviewing your privacy settings
            or selecting “delete Cookies.” You can find out how to do this by visiting www.allaboutcookies.org. For mobile devices, you can manage how your device and browser share certain device data by adjusting the privacy and security settings on
            your mobile device. Please note, however, that without Cookies, you may not be able to take full advantage of the Sites or our products or services. <br><br>​ CONTACT US <br> If you have any questions about this Cookie Policy, please contact
            us by e-mail at: <br> INFO@TRIPLEAAAPLUS.COM

        </p>
    </div>

</div>



<br> <br> <br>




<div class="py-5">
    <app-mini-contact-us></app-mini-contact-us>
</div>

<div class="pt-5">
    <app-footer></app-footer>
</div>