<app-menu>
</app-menu>
<div class="rel">

    <div class="jumbotron jumbotron-fluid jumbotron-custom pb-5">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
            <source src="\assets\services\Real states\AAA+ Banner - Real Estate 01.mp4" type="video/mp4">
        </video>
    </div>


    <h1 class="mainTitleBlack pt-5">
        INVERSIÓN INMOBILIARIA
    </h1>
    <div class="container-main ">

        <h3 class="mainSubTitle">"La inversión inmobiliaria, incluso a una escala muy pequeña, sigue siendo un medio probado y verdadero de construir el flujo financiero y la riqueza de una persona".</h3> <br> <br> <br>
        <p class="corporateText  container-text centerDiv">Debido a las necesidades y solicitudes de nuestros clientes, hemos creado una división de Inversión Inmobiliaria. Dicha división ofrece inversiones en proyectos de desarrollo inmobiliario, principalmente en el sur de Florida
            <br> <br> AAA+ facilita el proceso para que usted pueda formar parte de estas oportunidades. AAA+ maneja el proceso desde el inicio: incorporando la compañía, encontrando una buena propiedad para desarrollar, obteniendo los planos y permisos,
            construcción, supervisión, venta e impuestos; de este modo, usted no tiene nada de qué preocuparse. Invertir en estos proyectos le ha proporcionado buenos ingresos y diversificación a muchos clientes mientras mantienen parte de su patrimonio
            en activos seguros, como una propiedad en EE. UU..<br><br> Para mas información, visite: <span><a
                    href="www.vcvirtus.com"
                    target="_blank">www.vcvirtus.com</a></span><br> <br>
            <h2 class="container-text centerDiv projectsText">PROYECTOS</h2>
    </div>

    <div class="jumbotron jumbotron-fluid py-5">
        <div class="row py-5 mb">
            <div class="col-6 ng">
                <a href="
                http://www.focusdevelopmentusa.com/proyectos/2933-2/" target="_blank"><img class="manImg" src="\assets\services\Real states\ID03-Fachada-scaled.png" alt=""></a>
            </div>
            <div class="col-6 py-3 px-5 d-flex centerDiv align-items-center">
                <a href="
                http://www.focusdevelopmentusa.com/proyectos/2933-2/" target="_blank">
                    <p class="textTeam centerDiv align-Left ">
                        2933 SW 21st Ter, Miami, FL, 33145
                    </p>
                </a>
            </div>

        </div>

    </div>
    <div class="jumbotron jumbotron-fluid jumbotron-grey py-5">
        <div class="row mh">
            <div class="col-6 py-3 px-5 d-flex centerDiv align-items-center">
                <a href="http://www.focusdevelopmentusa.com/proyectos/3660-2/" target="_blank">
                    <p class=" textTeam centerDiv align-Left">
                        3660 SW 12th Street, Miami, FL, 33135
                    </p>

                </a>


            </div>
            <div class="col-6">
                <a href="
                http://www.focusdevelopmentusa.com/proyectos/3660-2/
                " target="_blank">
                    <img class="manImg" src="\assets\services\Real states\ID1-Fachada-1-scaled.png" alt=""></a>
            </div>
        </div>

    </div>

    <div class="jumbotron jumbotron-fluid py-5 thr-ju">
        <div class="row py-5 mb">
            <div class="col-6">
                <a href="http://www.focusdevelopmentusa.com/proyectos/10603-2/" target="_blank">
                    <img class="manImg" src="\assets\services\Real states\ID02-FP-scaled (1).png" alt="">
                </a>
            </div>
            <div class="col-6 centerDiv py-3 px-5 d-flex align-items-center">
                <a href="http://www.focusdevelopmentusa.com/proyectos/10603-2/" target="_blank">
                    <p class="textTeam centerDiv align-Left py-5 ">
                        10603 NE 11 ave, Miami Shores, FL, 33138
                    </p>
                </a>
            </div>

        </div>

    </div>

    <div class="py-0 ">
        <app-explorar-servicios></app-explorar-servicios>
    </div>
    <div class="cintillo">
        <div class="izquierda">
            <h2>¿Está interesado en los servicios de Triple A Plus?</h2>
            <h4>Reserve una llamada hoy mismo con nuestros asesores especializados.</h4>
        </div>
        <div class="derecha">
            <a href="https://calendly.com/aaawma" target="_blank">Reservar una llamada</a>
        </div>
    </div>
    <div class="pt-3 ">
        <app-mini-contacto></app-mini-contacto>
    </div>


    <div class="pt-5 ">
        <app-pie-pagina></app-pie-pagina>
    </div>

</div>
