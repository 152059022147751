<!-- Solo visible en escritorio -->
<div class="jumbotron jumbotron-fluid jumbotron-footer d-none d-sm-none d-md-block fot">

    <div class="text-jumbotron">
        <p>
            Gracias por su interés. <strong>AAA+ </strong> se reserva el derecho de no brindar servicios profesionales a PEPs, las Fuerzas Armadas y ONGs. Nuestro equipo de expertos está aquí para ayudarle y proporcionarle la mejor asesoría. Sus preguntas, comentarios y sugerencias son muy importantes para nosotros. Con su ayuda, podremos seguir proporcionando a nuestros
            clientes la satisfacción que se merecen.
        </p>
    </div>


    <div class="row d-flex-z container-icons">

        <div class="col-12 col-sm-4 d-flex align-items-center sub-container-icons">
            <a target="blank" href="tel:+ 1 305 420 5768" class="lin">
                <img class="svgAdjust" src="\assets\footer\HLM Artboard 29.svg" alt="">
                <p class="svgFont p-4">+ 1 (305) 420 5768</p>
            </a>
        </div>

        <div class="col-12 col-sm-4 d-flex align-items-center sub-container-icons">
            <a target="blank" class="linea" href="https://www.google.com/maps/place/777+Brickell+Ave+%23500,+Miami,+FL+33131,+USA/@25.7663884,-80.1904083,18.67z/data=!4m5!3m4!1s0x88d9b682e4bd7077:0x38d96919002ebfca!8m2!3d25.7664513!4d-80.1898953"><img class="svgAdjust" src="\assets\footer\HLM Artboard 30.svg" alt="">
                <p class="svgFont p-4 ">777 BRICKELLL AVE SUITE 500 MIAMI FL, 33131</p>
            </a>
        </div>

        <div class="col-12 col-sm-4 d-flex align-items-center sub-container-icons">
            <a href="mailto:INFO@TRIPLEAAAPLUS.COM" class="linea" target="blank"><img class="svgAdjust" src="\assets\footer\HLM Artboard 31.svg" alt="">
                <p class="svgFont p-4">INFO@TRIPLEAAAPLUS.COM</p>
            </a>
        </div>
    </div>

    <div class="enlaces">
        <a routerLink="/cookie-policy" class="cookiePolicyText" style="color: white;">Cookie policy</a>
        <p>|</p>
        <a class="disclaimerText" data-bs-toggle="modal" data-bs-target="#disclaimerModal">Disclaimer</a>
    </div>
    <div class="idioma">
        <p>Seleccione idioma:</p>
        <div class="links">
            <a routerLink="/inicio">ES</a>
            <p>|</p>
            <a routerLink="/home">EN</a>
        </div>
    </div>
    <div class="d-flex align-items-center">

        <div class="m-auto">
            <p class="textFinal">©Triple A Plus All Rights Reserved  <span></span></p>

            <p class="textFinal"><a href="https://www.estudiomontenegro.net">Developed by Estudio Montenegro</a></p>
        </div>

    </div>
</div>


<!-- Solo visible en movil -->
<div class="jumbotron jumbotron-fluid jumbotron-footer d-sm-block d-md-none">

    <div class="text-jumbotron py-5">
        <p>
            Gracias por su interés en AAA+. Nuestro equipo de expertos está aquí para ayudarle y proporcionarle la mejor asesoría. Sus preguntas, comentarios y sugerencias son muy importantes para nosotros. Con su ayuda, podremos seguir proporcionando a nuestros
            clientes la satisfacción que se merecen.
        </p>
    </div>


    <div class="row d-flex-z container-icons">

        <div class="col-12 col-sm-4 d-flex align-items-center sub-container-icons">
            <a target="blank" href="tel:+ 1 305 420 5768" class="lineamov">
                <img class="svgAdjust" src="\assets\footer\HLM Artboard 29.svg" alt="">
                <p class="svgFont">+ 1 (305) 420 5768</p>
            </a>
        </div>

        <div class="col-12 col-sm-4 d-flex align-items-center sub-container-icons">
            <a target="blank" class="lineamov" href="https://www.google.com/maps/place/777+Brickell+Ave+%23500,+Miami,+FL+33131,+USA/@25.7663884,-80.1904083,18.67z/data=!4m5!3m4!1s0x88d9b682e4bd7077:0x38d96919002ebfca!8m2!3d25.7664513!4d-80.1898953"><img class="svgAdjust" src="\assets\footer\HLM Artboard 30.svg" alt="">
                <p class="svgFont ">777 BRICKELLL AVE SUITE 500 MIAMI FL, 33131</p>
            </a>
        </div>

        <div class="col-12 col-sm-4 d-flex align-items-center sub-container-icons">
            <a href="mailto:INFO@TRIPLEAAAPLUS.COM" class="lineamov" target="blank"><img class="svgAdjust" src="\assets\footer\HLM Artboard 31.svg" alt="">
                <p class="svgFont">INFO@TRIPLEAAAPLUS.COM</p>
            </a>
        </div>
    </div>

    <br>


    <div class="row mobile-container mh">
        <div class="col" style="text-align: center;">
            <a routerLink="/cookie-policy" class="cookiePolicyText pt-5" style="color: white;">Cookie policy</a>

        </div>

        <div class="col" style="text-align: center;">
            <a class="disclaimerText" data-bs-toggle="modal" data-bs-target="#disclaimerModal">Disclaimer</a>
        </div>
    </div>
    <div class="idioma">
        <p>Seleccione idioma:</p>
        <div class="links">
            <a routerLink="/inicio">ES</a>
            <p>|</p>
            <a routerLink="/home">EN</a>
        </div>
    </div>
    <br><br>

    <div class="d-flex align-items-center">
        <div class="m-auto mobile-container">
            <p class="textFinal">©Triple A Plus All Rights Reserved  <span class="text-white"></span></p>
            <p class="textFinal"><a href="https://www.estudiomontenegro.net">Developed by Estudio Montenegro</a></p>
        </div>
    </div>
</div>


<!-- Vertically centered scrollable modal -->
<div class="modal fade" id="disclaimerModal" tabindex="-1" aria-labelledby="disclaimerModal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-bs-dismiss="modal" aria-label="Close"><a > <img
                            src="../../../../assets/footer/delete.svg" alt="Cerrar icon"></a> </button>
            </div>
            <div class="modal-body">
                <div class="img-pre">
                    <img src="../../../../assets/footer/HLM Artboard 37.svg" alt="Precaucion">
                    <h2 class="exploreTitle">Disclaimer</h2>
                </div>
                <br>
                <div class="txt-prev">
                    <p>Materials provided by Triple A Plus WMA and www.tripleaaaplus.com have been prepared for informational purposes only and should not be relied on for tax, legal, or accounting advice. Nothing on this webpage shall be construed as a
                        contractual commitment or as a warranty.</p>
                </div>
                <br>
                <div class="img-pre">
                    <img src="../../../../assets/footer/HLM Artboard 37.svg" alt="Precaucion">
                    <h2 class="exploreTitle"> Important</h2>
                </div>
                <br>
                <div class="txt-prev">
                    <p>Each bank account application is subject to the bank’s mandatory due diligence requirements and duties under national and international (FATF / AML / CFT) standards, in particular to prevent money laundering.</p>
                    <p>You have no obligation to opt for a bank account with the banks we work with and may seek another bank of your choice. Triple A Plus WMA is not offering banking services of any kind nor can we guarantee the assets held in the institution.
                        Our onboarding service includes the preparation of the application form, the submission of the application file and the assistance in navigating today’s compliance complexities that financial institutions now require. Every application
                        approval is subject to the final acceptance by the bank.</p>
                </div>
            </div>
        </div>
    </div>
</div>
