<app-navbar></app-navbar>
<div class="contenedor-gen">
    <div class="banner">
        <img src="./../../../../assets/corporate_offshore/bgnd01.jpg" alt="banner">
    </div>
    <div class="corporate-text">
        <div class="titulo">
            <h1 class="mainTitleBlack ">CORPORATE OFFSHORE PLANNING</h1>
        </div>
        <div class="parrafo">
            <p> <strong>AAA+</strong> can guide you in the process of incorporating an offshore company. Whether your needs and requirements are big or small, we can deliver them affordably. We offer years of experience in corporate offshore planning in different industries,
                markets and economies.</p>
        </div>
        <div class="boton">
            <a href="https://calendly.com/aaawma" target="_blank"><button>Let's Talk!</button></a>
        </div>
    </div>
    <div class="contenedor-cards">
        <div class="titulo">
            <h2>Why does AAA+ recommend using a <span>offshore company?</span></h2>
        </div>
        <div class="cards">
            <div class="card">
                <div class="imagen"><img class="blanco" src="../../../../assets/corporate_offshore/loss.svg" alt="Scale">
                    <img class="azul" src="../../../../assets/corporate_offshore/loss azul.svg" alt="Scale">
                </div>
                <p>Scale and lower operation costs</p>
            </div>
            <div class="card">
                <div class="imagen"><img class="blanco" src="../../../../assets/corporate_offshore/strategy.svg" alt="Strategy">
                    <img class="azul" src="../../../../assets/corporate_offshore/strategy azul.svg" alt="Strategy">
                </div>
                <p>Business strategy</p>
            </div>
            <div class="card">
                <div class="imagen"><img class="blanco" src="../../../../assets/corporate_offshore/verified.svg" alt="Verified">
                    <img class="azul" src="../../../../assets/corporate_offshore/verified azul.svg" alt="Verified">
                </div>
                <p>Privacy and confidentiality</p>
            </div>
            <div class="card">
                <div class="imagen"><img class="blanco" src="../../../../assets/corporate_offshore/tax.svg" alt="Tax"><img class="azul" src="../../../../assets/corporate_offshore/tax azul.svg" alt="Tax"></div>
                <p>Tax optimization</p>
            </div>
        </div>
        <div class="parrafo">
            <p>Incorporating an offshore company is totally legal. AAA+ will guide you in all the offshore company formation processes. We have a dedicated team who will gladly assist you.</p>
        </div>
    </div>
    <div class="contenedor-know">
        <div class="texto">
            <h4>knowledge</h4>
            <div class="titulo">
                <h3>Offshore companies' are <br> based in a different country <span>than your main business operations</span></h3>
            </div>
            <div class="parrafo">
                <p>Offshore company cannot operate in the registered country. If they do, they become onshore company and are required to pay local taxes and obligations.</p>
                <p>Another important fact is that the chairman, board of directors, and/or founders don't have to reside in the same country as the offshore company or use local talent. The incorporation shall not use the country's economy in local investments, subsidies, and local bank transfers.</p>
                <p>Offshore company provide a worldwide reach according to your strategy, privacy, confidentiality and in some cases, tax optimization.</p>
            </div>
            <div class="boton-img">
                <a href="https://calendly.com/aaawma" target="_blank"><button>Let's Talk!</button></a>
                <div class="imagen">
                    <h2>FAQ</h2>
                    <p>About offshore company</p>
                </div>
            </div>
        </div>
        <div class="imagen-der">
            <img src="./../../../../assets/corporate_offshore/imgvert01.jpg" alt="">
        </div>
    </div>
    <div class="contenedor-slider">
        <div id="carouselExampleControls" class="carousel slide desktop" data-bs-ride="carousel">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="row">
                        <div class="card">
                            <div class="imagen">
                                <img class="blanco" src="./../../../../assets/corporate_offshore/question.svg" alt="">
                                <img class="azul" src="./../../../../assets/corporate_offshore/question azul.svg" alt="">
                            </div>
                            <div class="texto">
                                <div class="titulo">
                                    <h2>What is an offshore company'?</h2>
                                </div>
                                <div class="parrafo">
                                    <p>An offshore company' is any company' based in a different country from your main business, generally for optimization or strategical reasons.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="imagen">
                                <img class="blanco" src="./../../../../assets/corporate_offshore/growth.svg" alt="">
                                <img class="azul" src="./../../../../assets/corporate_offshore/growth azul.svg" alt="">
                            </div>
                            <div class="texto">
                                <div class="titulo">
                                    <h2>What are the benefits that offshore company' offer?</h2>
                                </div>
                                <div class="parrafo">
                                    <p>Some of the benefits that offshore company' offer are scaled and lower operation costs, business strategies like protecting companies' assets, privacy and confidentiality, and tax optimization.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card ">
                            <div class="imagen">
                                <img class="blanco" src="./../../../../assets/corporate_offshore/idea.svg" alt="">
                                <img class="azul" src="./../../../../assets/corporate_offshore/idea azul.svg" alt="">
                            </div>
                            <div class="texto">
                                <div class="titulo">
                                    <h2>How do I create or incorporate an offshore company'?</h2>
                                </div>
                                <div class="parrafo">
                                    <p>The process required to incorporate an offshore company' may vary between countries and jurisdictions. Our dedicated team can gladly assist you.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card ">
                            <div class="imagen">
                                <img class="blanco" src="./../../../../assets/corporate_offshore/flag.svg" alt="">
                                <img class="azul" src="./../../../../assets/corporate_offshore/flag azul.svg" alt="">
                            </div>
                            <div class="texto">
                                <div class="titulo">
                                    <h2>What are the best countries or jurisdictions to incorporate offshore company?</h2>
                                </div>
                                <div class="parrafo">
                                    <p>Some of the countries we consider best to incorporate offshore company include British Virgin Islands, Marshall Islands, Panama, Seychelles; in some cases, Delaware and Florida LLC's.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="card col-3">
                            <div class="imagen">
                                <img class="blanco" src="./../../../../assets/corporate_offshore/question.svg" alt="">
                                <img class="azul" src="./../../../../assets/corporate_offshore/question azul.svg" alt="">
                            </div>
                            <div class="texto">
                                <div class="titulo">
                                    <h2>What is an offshore company'? </h2>
                                </div>
                                <div class="parrafo">
                                    <p>An offshore company' is any company' based in a different country from your main business, generally for optimization or strategical reasons.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card col-3">
                            <div class="imagen">
                                <img class="blanco" src="./../../../../assets/corporate_offshore/growth.svg" alt="">
                                <img class="azul" src="./../../../../assets/corporate_offshore/growth azul.svg" alt="">
                            </div>
                            <div class="texto">
                                <div class="titulo">
                                    <h2>What are the benefits that offshore company offer?</h2>
                                </div>
                                <div class="parrafo">
                                    <p>Some of the benefits that offshore comapanies offer are scaled and lower operation costs, businessstrategies like protecting company' assets, privacy and confidentiality, and tax optimization.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card col-3">
                            <div class="imagen">
                                <img class="blanco" src="./../../../../assets/corporate_offshore/idea.svg" alt="">
                                <img class="azul" src="./../../../../assets/corporate_offshore/idea azul.svg" alt="">
                            </div>
                            <div class="texto">
                                <div class="titulo">
                                    <h2>How do I create or incorporate an offshore company'?</h2>
                                </div>
                                <div class="parrafo">
                                    <p>the processes required to incorporate an offshore company' may vary between countries and jurisdictions. Our dedicated team can gladly assist you.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card col-3">
                            <div class="imagen">
                                <img class="blanco" src="./../../../../assets/corporate_offshore/flag.svg" alt="">
                                <img class="azul" src="./../../../../assets/corporate_offshore/flag azul.svg" alt="">
                            </div>
                            <div class="texto">
                                <div class="titulo">
                                    <h2>What are the best countries or jurisdictions to incorporate offshore company?</h2>
                                </div>
                                <div class="parrafo">
                                    <p>Some of the countries we consider best to incorporate offshore company include British Virgin Islands, Marshall Islands, Panama, Seychelles; in some cases, Delaware and Florida LLC's.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div id="carouselExampleControls" class="carousel slide mobile" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="card">
                        <div class="imagen">
                            <img class="blanco" src="./../../../../assets/corporate_offshore/question.svg" alt="">
                            <img class="azul" src="./../../../../assets/corporate_offshore/question azul.svg" alt="">
                        </div>
                        <div class="texto">
                            <div class="titulo">
                                <h2>What is an offshore company'?</h2>
                            </div>
                            <div class="parrafo">
                                <p>An offshore company' is any company' based in a different country from your main business, generally for optimization or strategical reasons.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card">
                        <div class="imagen">
                            <img class="blanco" src="./../../../../assets/corporate_offshore/growth.svg" alt="">
                            <img class="azul" src="./../../../../assets/corporate_offshore/growth azul.svg" alt="">
                        </div>
                        <div class="texto">
                            <div class="titulo">
                                <h2>What are the benefits that offshore company offer?</h2>
                            </div>
                            <div class="parrafo">
                                <p>Some of the benefits that offshore comapanies offer are scaled and lower operation costs, businessstrategies like protecting company' assets, privacy and confidentiality, and tax optimization.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card ">
                        <div class="imagen">
                            <img class="blanco" src="./../../../../assets/corporate_offshore/idea.svg" alt="">
                            <img class="azul" src="./../../../../assets/corporate_offshore/idea azul.svg" alt="">
                        </div>
                        <div class="texto">
                            <div class="titulo">
                                <h2>How do I create or incorporate an offshore company'?</h2>
                            </div>
                            <div class="parrafo">
                                <p>The processes required to incorporate an offshore company' may vary between countries and jurisdictions. Our dedicated team can gladly assist you.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card ">
                        <div class="imagen">
                            <img class="blanco" src="./../../../../assets/corporate_offshore/flag.svg" alt="">
                            <img class="azul" src="./../../../../assets/corporate_offshore/flag azul.svg" alt="">
                        </div>
                        <div class="texto">
                            <div class="titulo">
                                <h2>What are the best countries or jurisdictions to incorporate offshore company?</h2>
                            </div>
                            <div class="parrafo">
                                <p>Some of the countries we consider best to incorporate offshore company include British Virgin Islands, Marshall Islands, Panama, Seychelles; in some cases, Delaware and Florida LLC's.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item ">
                    <div class="card">
                        <div class="imagen">
                            <img class="blanco" src="./../../../../assets/corporate_offshore/question.svg" alt="">
                            <img class="azul" src="./../../../../assets/corporate_offshore/question azul.svg" alt="">
                        </div>
                        <div class="texto">
                            <div class="titulo">
                                <h2>What is an offshore company'? prueba</h2>
                            </div>
                            <div class="parrafo">
                                <p>An offshore company' is any company' based in a different country from your main business, generally for optimization or strategical reasons.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card">
                        <div class="imagen">
                            <img class="blanco" src="./../../../../assets/corporate_offshore/growth.svg" alt="">
                            <img class="azul" src="./../../../../assets/corporate_offshore/growth azul.svg" alt="">
                        </div>
                        <div class="texto">
                            <div class="titulo">
                                <h2>What are the benefits that offshore company offer?</h2>
                            </div>
                            <div class="parrafo">
                                <p>Some of the benefits that offshore comapanies offer are scaled and lower operation costs, businessstrategies like protecting company' assets, privacy and confidentiality, and tax optimization.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card ">
                        <div class="imagen">
                            <img class="blanco" src="./../../../../assets/corporate_offshore/idea.svg" alt="">
                            <img class="azul" src="./../../../../assets/corporate_offshore/idea azul.svg" alt="">
                        </div>
                        <div class="texto">
                            <div class="titulo">
                                <h2>How do I create or incorporate an offshore company'?</h2>
                            </div>
                            <div class="parrafo">
                                <p>The processes required to incorporate an offshore company' may vary between countries and jurisdictions. Our dedicated team can gladly assist you.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card ">
                        <div class="imagen">
                            <img class="blanco" src="./../../../../assets/corporate_offshore/flag.svg" alt="">
                            <img class="azul" src="./../../../../assets/corporate_offshore/flag azul.svg" alt="">
                        </div>
                        <div class="texto">
                            <div class="titulo">
                                <h2>What are the best countries or jurisdictions to incorporate offshore company?</h2>
                            </div>
                            <div class="parrafo">
                                <p>Some of the countries we consider best to incorporate offshore company include British Virgin Islands, Marshall Islands, Panama, Seychelles; in some cases, Delaware and Florida LLC's.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="container">
        <h2 class="mainTitleBlack min py-5">How could <br> AAA+ help you?</h2>
        <p class="corporateText container-text customT centerDiv">AAA+ offers offshore company' incorporation services in many jurisdictions. Our aim is to design robust corporate offshore structures that help you achieve your goals through personalized, efficient, and outstanding service. In addition, we make
            sure our clients’ information remains confidential while ensuring that it complies with current regulations.<br><br> We have assisted entrepreneurs, companies, and families from all over the world in designing the most appropriate corporate
            offshore structure to expand their business, protect their assets, and start new ventures, taking into consideration confidentiality, legal protection, and place of residency, among others.<br><br> AAA+ establishes these corporate offshore structures
            through long term partnerships with internationally recognized law firms and fiduciaries. </p>
    </div>

    <br><br><br><br>
    <div class="row py-5 customT customB pad-image">
        <div class="col-12 col-md-6"><img class="manImg" src="\assets\services\corporate\two-businesspeople-discussing-.png" alt=""></div>

        <div class="col-12 col-md-6 py-4 ">
            <h2 class="titleTeam"><br> Offshore Compani Formation & Administration</h2> <br> <br>
            <p class="textTeam container-text1">Depending on your needs, AAA+ offer offshore company formation and administration in many jurisdictions. We offer competitive pricing, fast processing times and the corresponding advice on where the company should be incorporated depending
                on what you’re trying to achieve.
            </p>
        </div>

    </div>
    <div class="jumbotron jumbotron-fluid jumbotron-grey pt-5 pb-3 pad-image inv">
        <div class="row inv">
            <div class="col-12 col-md-6 container-text2 container-mobile les-tx">
                <h2 class="titleTeam"><br><br> Private Interest Foundation / <br> Trust Formation & Administration</h2> <br> <br>

                <p class="textTeam">
                    If you are seeking efficient succession planning and wealth management structures we can assist in the incorporation of a Private Interest Foundation / Trust in various jurisdictions. These types of vehicles are part of a multi-entity structure which
                    provide the utmost protection for an enduring legacy while keeping the founders and settlers' wishes for coming generations.
                    <br> <br> We asses which type of structure is best for each family or individual, taking into account country of citizenship, country of residence, family and operational needs, assets to be protected, and jurisdictions where these
                    are located, among others.
                </p>
            </div>

            <div class="col-12 col-md-6 containerManImg2"><img class="manImg" src="\assets\services\corporate\man-working-at-night.png" alt=""></div>
        </div>

    </div>

    <div class="jumbotron jumbotron-fluid pt-1 pad-image">
        <div class="row pt-5">
            <div class="col-12 col-md-6"><img class="manImg" src="\assets\services\corporate\team-work-process-young-busine@2x.png" alt=""></div>
            <div class="col-12 col-md-6 py-3 px-5 les-tx">
                <h2 class="titleTeam"> <br> <br> <br> Professional Nominee Services</h2> <br> <br>
                <p class="textTeam container-text1">
                    If needed, we can provide you nominee professional directors, councilors, protector and shareholder services for all types of entities. These services provide a greater level of security and animosity for your protection while ensuring that your assets
                    are held in the most trustworthy manner.
                </p>
            </div>

        </div>

    </div>
    <div class="contenedor-business">
        <div class="titulo">
            <h2>Offshore business <span>strategy</span></h2>
        </div>
        <div class="parrafo">
            <p>
                AAA+ will be glad to help you develop an offshore business strategy tailored to your needs, providing affordable and confidential business solutions.
            </p>
        </div>
        <div class="boton">
            <button routerLink="/contact-us">Contact us today!</button>
        </div>
    </div>
</div>
<app-footer></app-footer>
