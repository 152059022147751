<header class="head fixed-top" [ngClass]="{ 'not-border': isCollapse }">
    <div class="container-fluid">
        <div class="logo-nav-container mob-nav">
            <a routerLink="/home" class="logo"><img src="\assets\cambios\logo\Logo AAA - Transparente Blanco.png" alt="..."></a>
        </div>
        <nav class="header menu">
            <input type="checkbox" id="check">
            <label for="check" class="checkbtn" (click)="toggleState()">
                <i><img class="barras" id="menu-bars" src="./../../../assets/home/lista.svg" alt="..."
                        ></i>
            </label>
            <ul class="rojos cerrar hei-hun" [ngClass]="{ 'open': isCollapse }">

                <li>
                    <a class="not-act" (click)="toggleState()" routerLink="/inicio" routerLinkActive="win-ac">
                        <span class="deco-ac">Inicio</span> <br> <span class="text-center pon-ac">•</span></a>
                </li>
                <li>
                    <a class="not-act" (click)="toggleState()" routerLink="/acerca-de-aaaplus" routerLinkActive="win-ac">
                        <span class="deco-ac">Nosotros</span> <br> <span class="text-center pon-ac">•</span></a>
                </li>
                <li>
                    <a class="not-act submenu desk-item" (click)="desplegarMenu('one')" routerLinkActive="win-ac" routerLink="/servicios">
                        <span class="deco-ac">Servicios</span> <br> <span class="text-center pon-ac">•</span>
                    </a>
                    <a class="not-act submenu mob-item mb-3 pb" routerLinkActive="win-ac">
                        <div style="display: flex !important; flex-direction: row ; justify-content: center;">
                            <span routerLink="/servicios" class="deco-ac">Servicios</span>
                            <!--  <br> <span class="text-center pon-ac">•</span> -->
                            <img class="mx-2" (click)="desplegarMenu('one')" src="../../../assets/home/flecha-hacia-abajo-para-navegar.svg" width="20px" alt="flecha">
                        </div>
                        <br> <br> <span class="text-center pon-ac">•</span>
                    </a>
                    <ul [ngClass]="{ 'abrirsubmenu': subMenus.one}" class="quien cerrarsubmenu">
                        <li>
                            <a class="not-act mt-5 less-mar5 now-tx" (click)="toggleState()" routerLink="/servicios/planificacion-corporativa-offshore" routerLinkActive="win-ac">Planificación
                                <br> Corporativa Internacional</a>
                        </li>
                        <!--     <li>
                            <a class="not-act now-tx mob-item" (click)="toggleState()" routerLink="/services"
                                routerLinkActive="win-ac">WHAT TO EXPECT?</a>
                        </li> -->
                        <li><a class="not-act now-tx" (click)="toggleState()" routerLink="/servicios/apertura-de-cuentas-bancarias" routerLinkActive="win-ac">Apertura de
                                 <br> cuentas bancarias</a></li>
                        <li><a class="not-act now-tx" (click)="toggleState()" routerLink="/servicios/contabilidad-y-Auditoria" routerLinkActive="win-ac">Contabilidad y Auditoría</a></li>
                        <li><a class="not-act now-tx" (click)="toggleState()" routerLink="/servicios/gestion-patrimonial" routerLinkActive="win-ac">Gestión
                                 <br> Patrimonial e Inversiones</a></li>
                        <li><a class="not-act now-tx" (click)="toggleState()" routerLink="/servicios/finanzas" routerLinkActive="win-ac">Finanzas</a></li>
                        <li><a class="not-act now-tx mb-2" (click)="toggleState()" routerLink="/servicios/asesoria-legal-y-planificacion-fiscal" routerLinkActive="win-ac">Asesoria Legal
                                <br> y planificación fiscal</a></li>
                    </ul>
                </li>

                <li class="logo-li desk-nav">
                    <a routerLink="/home" class="logo"><img src="\assets\cambios\logo\Logo AAA - Transparente Blanco.png" alt="..."></a>
                </li>
                <li>
                    <a class="not-act les-line mob-tp" (click)="toggleState()" routerLink="/inversion-inmobiliaria" routerLinkActive="win-ac">
                        <span class="deco-ac">Inversión <br><br> Inmobiliaria</span> <br> <span class="text-center pon-ac pt-2">•</span>
                    </a>
                </li>
                <li>
                    <a class="not-act mor-bt" (click)="toggleState()" routerLink="/contactanos" routerLinkActive="win-ac">
                        <span class="deco-ac"> Contáctanos</span> <br> <span class="text-center pon-ac">•</span>
                    </a>
                </li>
                <li>
                    <a><br><br><br><br></a>
                </li>
            </ul>
        </nav>
    </div>
</header>
