<header class="head fixed-top" [ngClass]="{ 'not-border': isCollapse }">
    <div class="container-fluid">
        <div class="logo-nav-container mob-nav">
            <a routerLink="/home" class="logo"><img src="\assets\cambios\logo\Logo AAA - Transparente Blanco.png" alt="..."></a>
        </div>
        <nav class="header menu">
            <input type="checkbox" id="check">
            <label for="check" class="checkbtn" (click)="toggleState()">
                <i><img class="barras" id="menu-bars" src="./../../../assets/home/lista.svg" alt="..."
                        ></i>
            </label>
            <ul class="rojos cerrar hei-hun" [ngClass]="{ 'open': isCollapse }">

                <li>
                    <a class="not-act" (click)="toggleState()" routerLink="/home" routerLinkActive="win-ac">
                        <span class="deco-ac">home</span> <br> <span class="text-center pon-ac">•</span></a>
                </li>
                <li>
                    <a class="not-act" (click)="toggleState()" routerLink="/about-us" routerLinkActive="win-ac">
                        <span class="deco-ac">about us</span> <br> <span class="text-center pon-ac">•</span></a>
                </li>
                <li>
                    <a class="not-act submenu desk-item" (click)="desplegarMenu('one')" routerLinkActive="win-ac" routerLink="/services">
                        <span class="deco-ac">Services</span> <br> <span class="text-center pon-ac">•</span>
                    </a>
                    <a class="not-act submenu mob-item mb-3 pb" routerLinkActive="win-ac">
                        <div style="display: flex !important; flex-direction: row ; justify-content: center;">
                            <span routerLink="/services" class="deco-ac">Services</span>
                            <!--  <br> <span class="text-center pon-ac">•</span> -->
                            <img class="mx-2" (click)="desplegarMenu('one')" src="../../../assets/home/flecha-hacia-abajo-para-navegar.svg" width="20px" alt="flecha">
                        </div>
                        <br> <br> <span class="text-center pon-ac">•</span>
                    </a>
                    <ul [ngClass]="{ 'abrirsubmenu': subMenus.one}" class="quien cerrarsubmenu">
                        <li>
                            <a class="not-act mt-5 less-mar5 now-tx" (click)="toggleState()" routerLink="/services/corporate-offshore-planning" routerLinkActive="win-ac">PLANNING
                                <br> INTERNATIONAL
                                CORPORATE</a>
                        </li>
                        <!--     <li>
                            <a class="not-act now-tx mob-item" (click)="toggleState()" routerLink="/services"
                                routerLinkActive="win-ac">WHAT TO EXPECT?</a>
                        </li> -->
                        <li><a class="not-act now-tx" (click)="toggleState()" routerLink="/services/bank-account-opening" routerLinkActive="win-ac">Bank
                                Account <br> Opening</a></li>
                        <li><a class="not-act now-tx" (click)="toggleState()" routerLink="/services/account-and-auditing" routerLinkActive="win-ac">Accounting/Auditing</a></li>
                        <li><a class="not-act now-tx" (click)="toggleState()" routerLink="/services/wealth-management-and-investments" routerLinkActive="win-ac">Wealth
                                Management <br> And Investments</a></li>
                        <li><a class="not-act now-tx" (click)="toggleState()" routerLink="/services/finance" routerLinkActive="win-ac">Finance</a></li>
                        <li><a class="not-act now-tx mb-2" (click)="toggleState()" routerLink="/services/legal-advice-and-tax-planning" routerLinkActive="win-ac">Legal
                                Advice & <br> Tax Planning</a></li>
                    </ul>
                </li>

                <li class="logo-li desk-nav">
                    <a routerLink="/home" class="logo"><img src="\assets\cambios\logo\Logo AAA - Transparente Blanco.png" alt="..."></a>
                </li>
                <li>
                    <a class="not-act les-line mob-tp" (click)="toggleState()" routerLink="/real-state-investment" routerLinkActive="win-ac">
                        <span class="deco-ac">real estate <br><br> investment</span> <br> <span class="text-center pon-ac pt-2">•</span>
                    </a>
                </li>
                <li>
                    <a class="not-act mor-bt" (click)="toggleState()" routerLink="/contact-us" routerLinkActive="win-ac">
                        <span class="deco-ac"> contact us</span> <br> <span class="text-center pon-ac">•</span>
                    </a>
                </li>
                <li>
                    <a><br><br><br><br></a>
                </li>
            </ul>
        </nav>
    </div>
</header>
