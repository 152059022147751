import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-inversion-inmobiliaria',
  templateUrl: './inversion-inmobiliaria.component.html',
  styleUrls: ['./inversion-inmobiliaria.component.scss'],
})
export class InversionInmobiliariaComponent implements OnInit {
  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle(' Real Estate Investment | Triple A+ ');
    this.meta.updateTag({
      name: 'description',
      content:
        'Triple A+ have set up a Real Estate Investment division that provides real estate development services, primarily in South Florida',
    });
    this.meta.updateTag({
      name: 'og:descripcion',
      content:
        'We have set up a Real Estate Investment division that provides real estate development services',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'Real Estate Investment, Real Estate Development Florida',
    });
    this.meta.updateTag({
      name: 'og:url',
      content: 'https://tripleaaaplus.com/real-estate-investment',
    });
    this.meta.updateTag({
      name: 'og:image',
      content: 'https://syr.us/l5e',
    });
    //Se  siguen agregando mas this.meta para las diferentes meta tags como keywords, url, image, etc
  }
}
