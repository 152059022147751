<app-menu></app-menu>
<div class="acon">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" loop autoplay playsinline preload="auto">
        <source class="" src="\assets\services\accountingAndAuditing\AAA+ Banner - Accounting Auditing.mp4" type="video/mp4">
      </video>
    </div>
    <br>

    <div class="mainTextAccounting pt-5 pb-5">
        <h1 class="mainTitleBlack corporateTitle py-5">CONTABILIDAD <br> Y AUDITORÍA
        </h1>
        <div class="container-text centerDiv">
            <p class="corporateText">A medida que el mundo evoluciona hacia un lugar más transparente y regulado, es de vital importancia cumplir con las regulaciones de la administración tributaria, local e internacional. Es por eso que <strong>AAA+</strong> trabaja de la mano con Firmas prestigiosas alrededor del mundo, comprometidass con el cumplimiento contable.</p>
        </div>
    </div>

    <br> <br> <br><br>

    <!-- <div class="jumbotron jumbotron-fluid jumbotron-video py-5">
    <video class="video" loop autoplay>
    <source src="\assets\services\About us\AAA+ Banner - About Us 01.mp4" type="video/mp4">
    </video>

</div> -->

    <div class=" pb-5">
        <app-explorar-servicios class="pt-5"> </app-explorar-servicios>
    </div>

    <div class="pt-3">
        <app-mini-contacto></app-mini-contacto>
    </div>

    <div class="pt-5">
        <app-pie-pagina></app-pie-pagina>
    </div>

</div>
