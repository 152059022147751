<app-menu></app-menu>
<div class="whe">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
        <source src="\assets\services\wealth-management\AAA+ Banner - Wealth Management and Investments.mp4" type="video/mp4">
        </video>
    </div>


    <div class="container">
        <h1 class="mainTitleBlack corporateTitle montserratSemiBold pt-5 pb-3">GESTIÓN PATRIMONIAL <br> E INVERSIONES</h1>

        <p class="mainText container-text corporateText container-text centerDiv">Sin importar si esta es su primera vez en el campo de las inversiones o si cuenta con años de experiencia; podemos diseñar un plan de inversió​n​ que se adapte perfectamente a sus objetivos financieros. <br> <br> Crear riqueza no es una tarea
            fácil, es por eso que consideramos de suma importancia poder ofrecer oportunidades de inversión tomando en consideración una debida planificación patrimonial, protección de activos, planificación de jubilación, lugar de residencia, entre otros.
            Podemos asesorarle sobre una amplia variedad de oportunidades de inversión alrededor del mundo, como instrumentos de renta fija, acciones, fondos mutuos, capital privado, programas de préstamos directos, colocaciones privadas, notas estructuradas,
            fondos de cobertura, commodities, divisas, fondos de jubilación y estudiantiles, vehículos de seguros, bienes raíces, entre otros. AAA+ se asegura de que cuando usted haga una inversión, ésta sea la mejor combinación de rendimiento vs riesgo;
            pero también que se ajuste a sus objetivos financieros.
        </p>
    </div>

    <br> <br> <br> <br>
    <!--  <div class="jumbotron jumbotron-fluid jumbotron-blue py-5 conf">

        <div class="container py-3">
            <div class="row py-5">
                <div class="col-3">
                    <img class=" svgText img-ca" src="\assets\services\wealth-management\HLM Artboard 32.svg" alt="">
                </div>
                <div class="col">
                    <h3 class="confidentialityTitle pb-2">
                        CONFIDENTIALITY
                    </h3>
                    <p class="confidentialityText">
                        In Triple A Plus we are committed to protect your confidentiality, which is accomplished by using closed and restricted channels of communication. Additionally, a single team of experts will give you all kinds of counseling necessary to achieve your goals.
                    </p>
                </div>
            </div>

            <div class="row pt-3">
                <div class="col">
                    <h3 class="confidentialityTitle ">
                        INTEGRITY
                    </h3>
                    <p class="confidentialityText">
                        We work to increase the reputation of Triple A Plus and our staff. Our consultants with high ethic and moral values work to keep long-term and solid relationships with our clients.
                    </p>
                </div>
                <div class="col-3">
                    <img class="svgText" src="\assets\services\wealth-management\HLM Artboard 33.svg" alt="">
                </div>
            </div>
        </div>
    </div>
 -->
    <div>
        <app-explorar-servicios></app-explorar-servicios>
    </div>

    <div class="pt-5">
        <app-mini-contacto></app-mini-contacto>
    </div>

    <div class="pt-5">
        <app-pie-pagina></app-pie-pagina>
    </div>
</div>