import { Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CargarScriptsService } from 'src/app/services/cargar-scripts.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

declare var $: any;
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
})
export class InicioComponent implements OnInit {
  @ViewChild('numbersDiv') numbersDiv: ElementRef;

  constructor(
    private renderer: Renderer2,
    private cargaScripts: CargarScriptsService,
    private title: Title,
    private meta: Meta
  ) {
    cargaScripts.Carga(['flickity.pkgd']);
    cargaScripts.Carga(['contador']);
  }

  public maxCountries = 7;
  public maxClients = 275;
  public maxYears = 0;

  ngOnInit(): void {
    this.title.setTitle(' Home | Triple A+ ');
    this.meta.updateTag({
      name: 'descripcion',
      content:
        'Our specialized and qualified team of advisors have experience with corporate offshore planning, bank account opening, accounting, auditing, wealth management, investments, real estate, legal advice and tax planning across the globe.',
    });
    this.meta.updateTag({
      property: 'og:descripcion',
      content:
        'Specialized and qualified team of advisors who have experience with corporate offshore planning across the globe.',
    });
    this.meta.updateTag({
      name: 'og:keywords',
      content: 'corporate offshore planning',
    });
    this.meta.updateTag({
      name: 'og:image',
      content: 'https://syr.us/l5e',
    });
    //Se  siguen agregando mas this.meta para las diferentes meta tags como keywords, url, image, etc
  }
  ngAfterViewInit() {
    this.countNumbers();
  }

  countNumbers() {
    setInterval(() => {
      if (this.maxClients <= 499) {
        this.maxClients++;
      }
    }, 3);

    setInterval(() => {
      if (this.maxCountries <= 58) {
        this.maxCountries++;
      }
    }, 49);

    setInterval(() => {
      if (this.maxYears <= 10) {
        this.maxYears++;
      }
    }, 241);
  }

  isVisible() {}
}
