import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CargarScriptsService } from 'src/app/services/cargar-scripts.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isCollapse = false;

  subMenus = {
    one: false,
    two: false,
    three: false,
  };


  desplegarMenu(menu: string): void {
    for (const key in this.subMenus) {
      if (Object.prototype.hasOwnProperty.call(this.subMenus, key)) {
        if (key === menu) {
          this.subMenus[key] = !this.subMenus[menu];
        } else {
          this.subMenus[key] = false;
        }
      }
    }
  }

  toggleState() {
    let foo = this.isCollapse;
    this.isCollapse = foo === false ? true : false;
    this.subMenus.one = false;
    this.subMenus.two = false;
    this.subMenus.three = false;
  }



  toggleState2() {
    let foo = this.isCollapse;
    this.isCollapse = foo === false ? true : false;
  }

  @ViewChild('menu-bars', { read: ElementRef }) menuBars: ElementRef;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

}
