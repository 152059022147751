import { Injectable } from '@angular/core';

const RESP = 'resp';
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {
    this.initialStorage();
  }

  public initialStorage(): void {
    const currents = JSON.parse(localStorage.getItem(RESP));
    if (!currents) {
      localStorage.setItem(RESP, JSON.stringify([]));
    }
  }
  set(key: string, data: boolean) {
    data = true;
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.log(e);
    }
  }
  get(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.log(e);
    }
  }

  setInicio(key: string, data: boolean) {
    data = true;
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.log(e);
    }
  }
  getIncio(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.log(e);
    }
  }
}
