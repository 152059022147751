<app-navbar></app-navbar>
<div class="abt" style="overflow: hidden;">

    <div class="jumbotron jumbotron-fluid jumbotron-custom">
        <video class="responsiveVideo" width="320" height="240" loop autoplay playsinline preload="auto">
            <source src="\assets\services\About us\AAA+ Banner - About Us 01.mp4" type="video/mp4">
        </video>
    </div>

    <div class="container pt-5 pb-1">

        <h1 class="corporateTitle fontMontserrat-SemiBold">About Us</h1>
        <div class="containerHeader">
            <h3 class="corporateSubtitle fontMontserratMedium">Protecting and creating wealth</h3>
        </div>
        <div class="container-text">
            <p class="corporateText ">Founded in 2010, AAA+ Wealth Management Advisors is an independent firm serving high net worth families, corporations and entrepreneurs from all over the world. AAA+ provides you with guidance and advice so you can thrive in a more competitive
                and regulated world.</p>
            <p class="corporateText "> For family-owned businesses, AAA+ provides the necessary wealth management and estate planning solutions to protect family members and their assets. In addition, we look for opportunities to make companies'' grow more efficiently.</p>
            <p class="corporateText "> Our guidance process starts with you. We focus on your life long success and hard work. We are committed to helping you achieve your financial goals.</p>
            <p class="corporateText "> At AAA+, we create tailor-made plans that evolve as the world changes, helping you stay efficient while ensuring that the needs of your family and business are met at the highest quality.</p>
        </div>

    </div>

    <div class="jumbotron jumbotron-fluid jumbotron-blue py-5 conf">

        <div class="container py-3">
            <div class="row py-5 mh">
                <div class="col-3">
                    <img class=" svgText img-ca" src="\assets\services\wealth-management\HLM Artboard 32.svg" alt="">
                </div>
                <div class="col">
                    <h3 class="confidentialityTitle ">
                        CONFIDENTIALITY
                    </h3>
                    <p class="confidentialityText">
                        We are committed to protect your confidentiality, which is accomplished by using closed and restricted channels of communication. Additionally, a single team of experts will give you all kinds of counseling necessary to achieve your goals.
                    </p>
                </div>
            </div>

            <div class="row pt-3 mb">
                <div class="col">
                    <h3 class="confidentialityTitle ">
                        INTEGRITY
                    </h3>
                    <p class="confidentialityText">
                        We work to increase the reputation of AAA+ and our staff. Our consultants with high ethical and moral values work to keep long-term and solid relationships with our clients.
                    </p>
                </div>
                <div class="col-2">
                    <img class="svgText" src="\assets\services\wealth-management\HLM Artboard 33.svg" alt="">
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="jumbotron jumbotron-fluid jumbotron-video py-5">
        <video class="video" loop autoplay>
          <source src="\assets\services\About us\AAA+ Banner - About Us 01.mp4" type="video/mp4">
          </video>
    </div> -->

    <!-- <br> <br> <br> -->

    <div class="pt-5">
        <app-mini-contact-us></app-mini-contact-us>
    </div>

    <div class="pt-5">
        <app-footer></app-footer>
    </div>
</div>